import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import App from './App';
import { api } from './api';
import "./style.css";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Login from './routes/LoginPage';
import Dashboard from './routes/home/Dashboard';
import CustomerList from './Pages/CustomerList';
import ProductList from './Pages/Products';
import SupplierList from './Pages/SupplierList';
import StockList from './Pages/StockList';
import Invoice from './Pages/Invoice';
import Layout from './Pages/Layout';
import { useSession, SessionContextProvider } from './Contexts/SessionContext';
const client = new ApolloClient({
  uri: api.baseUrl,
  cache: new InMemoryCache(),
});
const AppRouter = () => {
  const { isLoggedIn } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  useLayoutEffect(() => {
    if (!isLoggedIn && ['/dashboard', '/customer', '/supplier', '/product', '/stock', '/invoice']
      .some(route => location.pathname.startsWith(route))) {
      navigate('/', { replace: true });
    }
  }, [isLoggedIn, location.pathname, navigate]);
  return (
    <Routes>
      <Route path="/" element={<App />} />
      {isLoggedIn ? (
        <>
          <Route path="/dashboard" element={<Layout title="Home"><Dashboard /></Layout>} />
          <Route path="/customer" element={<Layout title="Customer"><CustomerList /></Layout>} />
          <Route path='/supplier' element={<Layout title="Supplier"><SupplierList /></Layout>} />
          <Route path="/product" element={<Layout title="Product"><ProductList /></Layout>} />
          <Route path="/stock" element={<Layout title="Stock"><StockList /></Layout>} />
          <Route path="/invoice" element={<Layout title="Invoice"><Invoice /></Layout>} />
        </>) : (
        <Route path="*" element={<App />} />
      )}
    </Routes>
  );
};
const rootElement = document.getElementById('root');
document.body.style.backgroundColor = "#f1f1f1";
document.body.style.overflowX = 'hidden';
if (rootElement) {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <React.StrictMode>
        <Router>
          <SessionContextProvider>
            <AppRouter />
          </SessionContextProvider>
        </Router>
      </React.StrictMode>
    </ApolloProvider>,
    document.getElementById('root')
  );
}

