import React from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import PheonixLoginButtonProps from "../interfaces/PheonixLoginButtonProps";
const PheonixButton: React.FC<PheonixLoginButtonProps> = ({
  label,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <MuiButton
      disabled={disabled}
      onClick={onClick}
      variant="outlined"
      style={{
        backgroundColor: disabled ? "lightgray" : "#1A504C",
        color: disabled ? "darkgray" : "white",
        padding: "5px",
        width: "350px",
        height: "42px",
        borderRadius: "4px",
        border: "1px",
        boxSizing: "border-box",
        marginLeft: "0",
        marginTop: "5%",
        left: "26px",
      }}
      {...props}
    >
      {label}
    </MuiButton>
  );
};
export default PheonixButton;
