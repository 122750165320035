import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { ERROR_MESSAGES, INVOICE_NUMBER } from "../constants";
import InvoiceSalesModalProps from "../interfaces/InvoiceSalesModalProps";
import PheonixInvoiceTable from "./PheonixInvoiceTable";
import { RowData, TableColumn } from "../interfaces/PheonixInvoiceTableProps";
import PheonixButton from "./PheonixButton";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Autocomplete,
} from "@mui/material";
import {
  GET_CUSTOMERS,
  GET_SUPPLIERS,
  GET_ALL_CUSTOMERS,
  GET_ALL_SUPPLIERS,
} from "../graphql/queries";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomerProps from "../interfaces/CustomerProps";
import SupplierProps from "../interfaces/SupplierProps";
import PheonixPurchaseTable from "./PheonixPurchaseTable";
const InvoiceSalesModal = <T extends object>({
  open,
  onClose,
  title,
  fields,
  onConfirm,
  close,
  confirmButtonText,
  formData,
  setFormData,
  isEditModal,
  errorMessage,
  onBlur,
  onFocus,
  disabled,
  selectedTab,
  ...rest
}: InvoiceSalesModalProps<T>) => {
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [customersData, setCustomersData] = useState<
    { id: string; name: string }[]
  >([]);
  const [supplierData, setSupplierData] = useState<
    { id: string; name: string }[]
  >([]);
  const [customerName, setCustomerName] = useState<
    { id: string; name: string }[]
  >([]);
  const [addressLines, setAddressLines] = useState<number>(1);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [rows, setRows] = useState<RowData[]>([
    {
      sNo: 1,
      customerName: "",
      invoiceNo: "",
      MRP: "",
      ExpiryDate: "",
      Quantity: "",
      GST: "",
      Amount: "",
    },
  ]);
  const [quantityErrors, setQuantityErrors] = useState<Array<string>>([""]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const limit = rowsPerPage;
  const [invoiceNetAmount, setInvoiceNetAmount] = useState<number | null>(null);
  const [invoicetotal, setInvoiceTotal] = useState<number | null>(null);
  const [cgst, setCgst] = useState<number | null>(null);
  const [tax, setTax] = useState<number | null>(null);
  const [sgst, setSgst] = useState<number | null>(null);
  const [igst, setIgst] = useState<number | null>(null);
  const [discount, setDiscount] = useState<number | null>(null);
  const [totItems, setTotItems] = useState<number | null>(null);
  const [totQuantity, setTotQuantity] = useState<number | null>(null);
  const [tableData, setTableData] = useState<CustomerProps[]>([]);
  const [isAllRowComplete,setIsALLRowComplete]= useState<boolean>(true);

  const {
    loading: customersLoading,
    error: customersError,
    data: customers,
    refetch: refetchCustomers,
  } = useQuery(GET_ALL_CUSTOMERS, {
    onCompleted: (data) => {
      setCustomersData(
        data.getCustomerList.map((stock: CustomerProps) => ({
          id: stock.id,
          name: stock.customer_name,
        }))
      );
    },
  });

  const {
    loading: suppliersLoading,
    error: suppliersError,
    data: suppliersData,
    refetch: refetchSuppliers,
  } = useQuery(GET_ALL_SUPPLIERS, {
    onCompleted: (data) => {
      setSupplierData(
        data.getSupplierList.map((stock: SupplierProps) => ({
          id: stock.id,
          name: stock.supplier_name,
        }))
      );
    },
  });

  useEffect(() => {
    if (!customersLoading && !customersError && customers) {
      setCustomersData(
        customers.getCustomerList.map((stock: CustomerProps) => ({
          id: stock.id,
          name: stock.customer_name,
        }))
      );
    }
    if (!suppliersLoading && !suppliersError && suppliersData) {
      setSupplierData(
        suppliersData.getSupplierList.map((stock: SupplierProps) => ({
          id: stock.id,
          name: stock.supplier_name,
        }))
      );
    }
  }, [
    customers,
    customersLoading,
    customersError,
    suppliersData,
    suppliersError,
    suppliersLoading,
  ]);

  const generateEmptyRow = () => ({
    sNo: 1,
    customerName: "",
    invoiceNo: "",
    MRP: "",
    ExpiryDate: "",
    Quantity: "",
    GST: "",
    Amount: "",
  });

  const resetRows = () => {
    setRows([generateEmptyRow()]);
  };
  useEffect(() => {
    resetRows();
    setCustomerName([]);
    setQuantityErrors([""]);
  }, [open]);

  useEffect(() => {
    refetchCustomers();
    refetchSuppliers();
  }, []);

  const handleAddRow = () => {
    const newRow = {
      sNo: rows.length + 1,
      ProductName: "",
      BatchNumber: "",
      MRP: "",
      ExpiryDate: "",
      Quantity: "",
      GST: "",
      Amount: "",
    };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (index: number) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const columns = [
    {
      field: "ProductName",
      headerName: "Product Name",
      width: "20%",
      padding: "16px",
    },
    {
      field: "BatchNumber",
      headerName: "Batch No",
      width: "12%",
      padding: "9px",
    },
    {
      field: "MRP",
      headerName: "MRP",
      width: "10%",
      padding: "17px",
    },
    {
      field: "PTR",
      headerName: selectedTab === "1" ? "PTR" : "RetailMargin",
      width: "12%",
      padding: "12px",
    },
    {
      field: "ExpiryDate",
      headerName: "Expiry Date",
      width: "15%",
      padding: "2px",
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      width: "11%",
      padding: "2px",
    },
    {
      field: "GST",
      headerName: "GST",
      width: "10%",
      padding: "0px",
    },
    {
      field: "Amount",
      headerName: "Amount",
      width: "12%",
      padding: "8px",
    },
    {
      field: "Action",
      headerName: "Action",
      width: "8%",
      padding: "14px",
      render: (index: number) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => handleDeleteRow(index)}
            style={{ color: "#D32F2F" }}
          >
            <RemoveCircleIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const isRowComplete = (row: any) => {
    for (const column of columns) {
      if (column.field !== "Action" && !row[column.field]) {
        return false;
      }
    }
    return true;
  };

  const isTableComplete = () => {
    for (const row of rows) {
      if (!isRowComplete(row)) {
        return false;
      }
    }
    return true;
  };

  const handleNetAmountChange = (
    newNetAmount: number | null,
    cgst: number | 0,
    items: number | null,
    quantity: number | null,
    total: number | null,
    tax: number | null,
    igst: number | null,
    discount: number | null
  ) => {
    setInvoiceNetAmount(newNetAmount);
    setInvoiceTotal(total);
    setCgst(cgst);
    setTotItems(items);
    setTotQuantity(quantity);
    setTax(tax);
    setIgst(igst);
    setDiscount(discount);
  };

  useEffect(() => {
    const hasCompleteRow = isTableComplete();
    if (
      selectedTab === "1" &&
      hasCompleteRow &&
      customerName &&
      Object.keys(customerName).length > 0 &&
      quantityErrors.every((error) => error === "")
    ) {
      setIsButtonDisabled(false);
    } else if (
      selectedTab === "2" &&
      customerName &&
      Object.keys(customerName).length > 0 &&
      hasCompleteRow &&
      invoiceNetAmount !== null
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [rows, customerName, quantityErrors, handleNetAmountChange]);

  const handleInputChange = (
    index: number,
    fieldName: keyof (typeof rows)[0],
    value: string
  ) => {
    const newRows = rows.map((row, rowIndex) => {
      if (index === rowIndex) {
        return { ...row, [fieldName]: value };
      }
      return row;
    });
    setRows(newRows);
  };
  const getCurrentDate = () => {
    const currentDate = new Date();
    return currentDate.toLocaleDateString("en-GB");
  };
  const handleInputChange1 = (
    index: number,
    field: string,
    value: string,
    available: string
  ) => {
    const updatedRows: RowData[] = rows.map((row, i) => {
      if (i === index) {
        const updatedRow = { ...row, [field]: value };

        if (value === null || value.trim() === "") {
          updatedRow["Amount"] = 0;
          setQuantityErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            newErrors[index] = "";
            return newErrors;
          });
        } else {
          const quantity = parseInt(value) || 0;
          const ptr = parseFloat(updatedRow["PTR"]) || 0;
          updatedRow["Amount"] = (quantity * ptr).toFixed(2);
          if (quantity > parseInt(available, 10)) {
            setQuantityErrors((prevErrors) => {
              const newErrors = [...prevErrors];
              newErrors[index] = ERROR_MESSAGES.MORE_QUANTITY;
              return newErrors;
            });
          } else {
            setQuantityErrors((prevErrors) => {
              const newErrors = [...prevErrors];
              newErrors[index] = "";
              return newErrors;
            });
          }
        }

        return updatedRow;
      }
      return row;
    });

    setRows(updatedRows);
  };

  const handleInvoiceTableChange = (updatedRows: RowData, index: number) => {
    const newRows = [...rows];
    newRows[index] = updatedRows;
    setRows(newRows);
  };

  const handleFieldChange = (fieldLabel: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [fieldLabel]: value }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
  };

  const handleNameChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setCustomerName(newValue);
  };

  const handleConfirm = () => {
    onConfirm(
      customerName,
      invoiceNetAmount,
      cgst,
      tax,
      totItems,
      totQuantity,
      invoicetotal,
      rows,
      igst,
      discount
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialogTitle-root": {
          color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
          fontFamily: "Inter",
          display: "flex",
        },
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          backgroundColor: "#fff !important",
          color: "rgba(0, 0, 0, 0.87) !important",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
          position: "relative !important",
          overflowY: "auto !important",
          display: "flex !important",
          flexDirection: "column !important",
          height: "754px !important",
          maxWidth: "1080px",
          padding: "4px 42px",
        },
        "& .css-uhb5lp": {
          backgroundColor: "#fff !important",
          color: "rgba(0, 0, 0, 0.87) !important",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
          position: "relative !important",
          overflowY: "auto !important",
          display: "flex !important",
          flexDirection: "column !important",
          height: "754px !important",
          maxWidth: "1080px",
          padding: "4px 42px",
        },
      }}
    >
      <DialogContent>
        <div
          style={{
            justifyContent: "flex-end",
            display: "flex",
            padding: "40px 38px 0px 69px",
            marginRight: "30px",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontWeight: "700",
              fontFamily: "Inter",
            }}
          >
            {ERROR_MESSAGES.INVOICEDATE}:
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: "400",
              fontFamily: "Inter",
              textDecoration: "underline",
              marginLeft: "5px",
            }}
          >
            {getCurrentDate()}
          </span>
        </div>

        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    padding: "20px 0px 0px 5px",
                    marginTop: "-25px",
                  }}
                >
                  <TableCell
                    style={{
                      border: "none",
                      fontSize: "16px",
                      fontWeight: "700",
                      fontFamily: "Inter",
                      color: "black",
                    }}
                  >
                    {title}
                  </TableCell>
                  <Autocomplete
                    id="combo-box-demo"
                    options={selectedTab === "1" ? customersData : supplierData}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) =>
                      handleNameChange(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          selectedTab === "1"
                            ? ERROR_MESSAGES.CUSTOMER_NAME1
                            : ERROR_MESSAGES.SUPPLIER_NAME1
                        }
                        style={{ color: "black" }}
                        sx={{
                          "& label.Mui-focused": {
                            color: "#1A504C",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "green",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#1A504C!important",
                            },
                          },
                        }}
                      />
                    )}
                    style={{ width: "30%", color: "black" }}
                  />
                </div>

                <TableContainer style={{ marginTop: "24px" }}>
                  <Table>
                    <div>
                      {selectedTab === "1" ? (
                        <PheonixInvoiceTable
                          rows={rows}
                          columns={columns}
                          handleInputChange={handleInputChange1}
                          handleInvoiceTableChange={handleInvoiceTableChange}
                          quantityErrors={quantityErrors}
                          handleNetAmountChange={handleNetAmountChange}
                          handleAddRow={handleAddRow}
                        />
                      ) : (
                        <PheonixPurchaseTable
                          rows={rows}
                          columns={columns}
                          handleInputChange={handleInputChange}
                          handleInvoiceTableChange={handleInvoiceTableChange}
                          quantityErrors={quantityErrors}
                          handleNetAmountChange={handleNetAmountChange}
                          handleAddRow={handleAddRow}
                        />
                      )}
                    </div>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <PheonixButton
          onClick={onClose}
          style={{
            backgroundColor: "#165D59",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            fontFamily: "Inter",
            marginBottom: "15px",
            marginRight: "30px",
            padding: "6px 16px",
            width: "146px",
            height: "36px",
          }}
        >
          {ERROR_MESSAGES.CANCEL}
        </PheonixButton>
        <PheonixButton
          disabled={isButtonDisabled}
          onClick={handleConfirm}
          style={{
            backgroundColor: isButtonDisabled ? "lightgray" : "#165D59",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            fontFamily: "Inter",
            marginBottom: "15px",
            marginRight: "15px",
            padding: "6px 16px",
            width: "176px",
            height: "36px",
          }}
        >
          {confirmButtonText}
        </PheonixButton>
      </DialogActions>
    </Dialog>
  );
};
export default InvoiceSalesModal;
