import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PheonixButton from "./PheonixButton";
import DeleteModalProps from "../interfaces/DeleteModalProps";
import { ERROR_MESSAGES } from "../constants";
const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  onConfirm,
  entity,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialogTitle-root": {
          color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
          fontFamily: "Inter",
          display: "flex",
        },
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          backgroundColor: "#fff !important",
          color: "rgba(0, 0, 0, 0.87) !important",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
          position: "relative !important",
          overflowY: "auto !important",
          display: "flex !important",
          flexDirection: "column !important",
          height: "247px !important",
          width: "552px",
          padding: "4px 42px",
        },
        "& .css-uhb5lp": {
          backgroundColor: "#fff !important",
          color: "rgba(0, 0, 0, 0.87) !important",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
          position: "relative !important",
          overflowY: "auto !important",
          display: "flex !important",
          flexDirection: "column !important",
          height: "247px !important",
          width: "552px",
          padding: "4px 42px",
        },
      }}
    >
      <DialogTitle
        style={{
          marginLeft: "-13px",
          fontSize: "24px",
          fontFamily: "Inter",
          marginTop: "20px",
        }}
      >
        {ERROR_MESSAGES.DELETE_CONFIRMATION}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: "67px",
            top: "45px",
            width: " 24px",
            height: " 24px",
            padding: "5px",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          display: "inline-flex",
          fontSize: "16px",
          color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
          letterSpacing: "0.15px",
          fontWeight: 400,
          paddingTop: "1px",
          marginLeft: "-13px",
          fontFamily: "Inter",
        }}
      >
        {ERROR_MESSAGES.DELETE_MSG} {entity}?
      </DialogContent>
      <DialogActions>
        <PheonixButton
          onClick={onClose}
          style={{
            color: "white",
            backgroundColor: "#165D59",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            fontFamily: "Inter",
            marginBottom: "32px",
            marginRight: "15px",
            padding: "6px 16px",
            width: "84px",
            height: "36px",
          }}
        >
          {" "}
          {ERROR_MESSAGES.CLOSE}
        </PheonixButton>
        <PheonixButton
          onClick={onConfirm}
          style={{
            color: "white",
            backgroundColor: "#D32F2F",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            fontFamily: "Inter",
            marginBottom: "30px",
            marginRight: "15px",
            padding: "6px 16px",
            width: "84px",
            height: "36px",
          }}
        >
          {ERROR_MESSAGES.DELETE}
        </PheonixButton>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteModal;
