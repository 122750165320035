import React from 'react';
import Login from "./routes/LoginPage"
const App: React.FC = () => {
  return (
   <div>
    <Login/>
   </div>
  );
};

export default App;
