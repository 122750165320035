import React, { ReactNode } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PheonixAppbarProps from "../interfaces/PheonixAppbarProps";
const PheonixAppBar: React.FC<PheonixAppbarProps> = ({
  title,
  children,
  isSidebarOpen,
}) => {
  const titleMarginLeft = isSidebarOpen ? 250 : 100;
  return (
    <div
      style={{
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: "white",
        height: "48px",
        width: "100%",
        padding: "10px 0 ",
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        position: "relative",
        overflowX: "hidden",
      }}
    >
      <h2
        style={{
          fontSize: "24px",
          fontWeight: "700",
          marginLeft: titleMarginLeft,
          flex: "1",
          fontFamily: "Inter",
          alignItems: "center",
        }}
      >
        {title}
      </h2>
      {children}
    </div>
  );
};
export default PheonixAppBar;
