import React, { ChangeEvent, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SnackbarContent from "@mui/material/SnackbarContent";
import { Button, FormHelperText, Alert } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container, styled } from "@mui/material";
import { Stack } from "@mui/material";
import { api } from "../api";
import axios from "axios";
import { useSession } from "../Contexts/SessionContext";
import { ERROR_MESSAGES } from "../constants";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PheonixLoginButton from "../components/PheonixLoginButton";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "../graphql/mutations";
import PheonixLoginSnackbar from "../components/PheonixSnackbar";
import { PHARMA_TEXT, Login_TEXT } from "../constants";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PhoenixLoginTextField from "../components/PheonixLoginTextField";
const Item = styled("div")({
  marginBottom: "20px",
  padding: "8px",
  textAlign: "center",
});
const Login: React.FC = () => {
  const [email, setemail] = useState(localStorage.getItem("email") || "");
  const [password, setpassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [pasErrorMessage, setPasErrorMessage] = useState("");
  const [succMessage, setSuccMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const theme = useTheme();
  const { isLoggedIn, logins, logout } = useSession();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const paperStyle = isMobile
    ? { padding: "20px", width: "auto", height: "auto" }
    : { padding: "50px", width: "444px", height: "550px" };

  const handleemailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setemail(e.target.value);
  };
  const handlepasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setpassword(e.target.value);
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleEmailFocus = () => {
    setErrorMessage("");
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  useEffect(() => {
    const emailValid = isValidEmail(email);
    const passwordValid = isValidPassword(password);
    if (!emailValid || !passwordValid) {
      setIsLoginButtonDisabled(false);
    } else {
      setIsLoginButtonDisabled(true);
    }
  }, [email, password]);

  useEffect(() => {
    handleLogoutClick();
  }, []);

  const handleLogoutClick = () => {
    try {
      navigate("/", { replace: true });
      logout();
      localStorage.removeItem("userId");
    } catch (error) {
      console.error("Error occurred during logout:", error);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const isValidPassword = (password: string): boolean => {
    const minLength = 8;
    return password.length >= minLength;
  };
  const handleChange = () => {
    const updatedRememberMe = !rememberMe;
    setRememberMe(updatedRememberMe);
    if (updatedRememberMe) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    } else {
      localStorage.setItem("email", "");
      localStorage.setItem("password", "");
    }
  };
  const handleEmailBlur = () => {
    const emailRegex = ERROR_MESSAGES.EMAIL_REG;
    if (!emailRegex.test(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL1);
    } else {
      setErrorMessage("");
    }
  };
  const handlePasswordBlur = () => {
    const minLength = 8;
    const passlength = password.length >= minLength;
    if (!password) {
      setPasErrorMessage(ERROR_MESSAGES.VALID_PASSWORD);
    } else if (!passlength) {
      setPasErrorMessage(ERROR_MESSAGES.PWD_LENGTH);
    } else {
      setPasErrorMessage("");
    }
  };
  const handlePasswordFocus = () => {
    setPasErrorMessage("");
  };
  function isValidEmail(email: string): boolean {
    const emailRegex = ERROR_MESSAGES.EMAIL_REG;
    return emailRegex.test(email);
  }
  const [login, { data }] = useMutation(LOGIN_MUTATION);
  const handleLogin = async () => {
    if (!isValidEmail(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
      return;
    }
    if (!isValidPassword(password)) {
      setPasErrorMessage(ERROR_MESSAGES.PWD_LENGTH);
      return;
    }
    try {
      const response = await login({
        variables: { email, password },
      });
      const { success, message } = response.data.login;
      const responseData = response.data.login;
      if (responseData.user) {
        setSnackbarMessage("Login Success");
        const userId = responseData.user.id;
        localStorage.setItem("userId", userId);
        setSuccess(true);
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          navigate("/dashboard");
        }, 3000);
        logins();
      } else {
        const error = message.toLowerCase();
        if (error.includes("email")) {
          setErrorMessage(message);
        } else if (error.includes("password")) {
          setPasErrorMessage(message);
        } else {
          setErrorMessage(message);
        }
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.ERROR, error);
    }
  };
  const handleClose = () => {
    setModalOpen(false);
    navigate("/dashboard");
  };
  const customCloseIcon = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      style={{ justifyContent: "end", width: "200px" }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container
        maxWidth="xs"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Paper
          elevation={3}
          style={{
            width: "400px",
            height: "600px",
            padding: "0px 16px",
            position: "absolute",
            borderRadius: "0",
          }}
        >
          <Stack spacing={7} direction="column">
            <Box component="form" onKeyPress={handleKeyPress}>
              <Item>
                <Typography
                  variant="h6"
                  style={{
                    color: "#1A504C",
                    marginBottom: "5px",
                    marginTop: "65px",
                    fontFamily: "Inter",
                  }}
                >
                  {" "}
                  {PHARMA_TEXT}
                </Typography>
              </Item>
              <Item>
                <Typography
                  variant="h6"
                  style={{
                    color: "#1A504C",
                    marginBottom: "5px",
                    marginTop: "-5px",
                    fontFamily: "Inter",
                  }}
                >
                  {Login_TEXT}
                </Typography>
              </Item>
              <Item>
                <PhoenixLoginTextField
                  id="outlined-error"
                  type="email"
                  value={email}
                  placeholder="Email"
                  label="Email"
                  variant="outlined"
                  onChange={handleemailChange}
                  onFocus={handleEmailFocus}
                  onBlur={handleEmailBlur}
                  error={Boolean(errorMessage)}
                  helperText={errorMessage || ""}
                  style={{
                    width: "350px",
                    height: "56px",
                    marginTop: "0%",
                  }}
                />
              </Item>
              <Item>
                <PhoenixLoginTextField
                  id="outlined-error"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  placeholder="Password"
                  label="Password"
                  variant="outlined"
                  onChange={handlepasswordChange}
                  onFocus={handlePasswordFocus}
                  onBlur={handlePasswordBlur}
                  error={Boolean(pasErrorMessage)}
                  helperText={pasErrorMessage || ""}
                  style={{
                    width: "350px",
                    marginTop: "0%",
                    height: "56px",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        sx={{ marginTop: "1%" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Item>
              <PheonixLoginButton
                label="Login"
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleLogin}
                disabled={!isLoginButtonDisabled}
              ></PheonixLoginButton>
            </Box>
          </Stack>
        </Paper>
      </Container>
      <PheonixLoginSnackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <span>{snackbarMessage}</span>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
              sx={{ marginLeft: "150px" }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        }
        vertical="top"
        horizontal="center"
        sx={{
          marginTop: "30px",
          width: "320px",
          height: "42px",
          "& .MuiSnackbarContent-root": {
            background: "#1A504C",
          },
        }}
      />
    </Box>
  );
};
export default Login;
