import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  TextareaAutosize,
  IconButton,
  Autocomplete,
} from "@mui/material";
import stockModalProps from "../interfaces/stockModalProps";
import PheonixButton from "./PheonixButton";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { ERROR_MESSAGES } from "../constants";

const StockModal = <T extends Record<string, any>>({
  open,
  onClose,
  title,
  fields,
  onConfirm,
  confirmButtonText,
  formData,
  onBlur,
  onFocus,
  setFormData,
  isEditModal,
  errorMessage,
  phonenoErrorMessage,
  setErrorMessage,
  setPhonenoErrorMessage,
  nameerrorMessage,
  addresserrorMessage,
  setNameErrorMessage,
  setAddressErrorMessage,
  dropdowndata,
  disabled,
  close,
  ...rest
}: stockModalProps<T>) => {
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(""));
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [expiryDate1, setExpiryDate1] = useState<Date | null>(null);
  useEffect(() => {
    if (open) {
      setSelectedProduct(formData["Product Name"]);
    } else {
      setSelectedProduct(null);
    }
  }, [open, formData]);

  const handleProductChange = (
    event: React.ChangeEvent<{}>,
    value: string | null
  ) => {
    setSelectedProduct(value);
    setFormData((prevData) => ({ ...prevData, "Product Name": value || "" }));
  };
  const handleExpiryDateChange = (date: Date | null) => {
    const formattedDate = date ? dayjs(date).format("DD/MM/YYYY") : "";
    setFormData((prevData) => ({ ...prevData, "Expiry Date": formattedDate }));
    setExpiryDate(date);
  };

  const handleProductChange1 = (value: Date | null) => {
    if (value) {
      const newValueAsDayjs = dayjs(value);
      setValue(newValueAsDayjs);
      setFormData((prevData) => ({
        ...prevData,
        "Expiry Date": newValueAsDayjs.format("DD/MM/YYYY"),
      }));
    } else {
      setValue(null);
      setFormData((prevData) => ({ ...prevData, "Expiry Date": "" }));
    }
  };
  const [inputValue, setInputValue] = React.useState("");
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  useEffect(() => {
    if (open) {
      setFieldErrors({});
      setIsButtonDisabled(!isEditModal);
    }
    if (isEditModal) {
      const dateString = formData["Expiry Date"];
      const dateParts = dateString.split("/");
      const month = parseInt(dateParts[1], 10);
      const day = parseInt(dateParts[0], 10);
      const year = parseInt(dateParts[2], 10);
      const expiryDateValue = new Date(year, month - 1, day);
      if (!isNaN(expiryDateValue.getTime())) {
        setExpiryDate1(expiryDateValue);
      }
    }
  }, [open, isEditModal]);

  useEffect(() => {
    validateForm();
  }, [formData]);

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    setIsConfirmDisabled(hasErrors);
  }, [fieldErrors]);
  const validateForm = () => {
    let hasErrors = false;
    let isAnyFieldEmpty = false;
    fields.forEach((field) => {
      const fieldValue = formData[field.label].trim();
      if (!fieldValue) {
        isAnyFieldEmpty = true;
      } else {
        validateField(field.label);
      }
    });
    setIsButtonDisabled(isAnyFieldEmpty);
  };
  const handleFieldChange = (fieldLabel: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [fieldLabel]: value }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
    validateForm();
  };
  const handleFieldBlur = (fieldLabel: string) => {
    validateField(fieldLabel);
  };
  const validateField = (fieldLabel: string) => {
    const fieldValue = (formData as { [key: string]: string })[fieldLabel];
    switch (fieldLabel) {
      case "Product Name":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.PROD_REQ,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Batch Number":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.BATCH_REQ,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "MRP":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MRP_REQ,
          }));
        } else if (!/^\d+(\.\d+)?$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MRP_VAL,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Expiry Date":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.DATE_REQ,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Quantity":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.QUANTITY_REQ,
          }));
        } else if (!/^\d+$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.QUANTITY_VAL,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      default:
        break;
    }
  };
  const handleConfirm = () => {
    validateForm();
    onConfirm();
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDialogTitle-root": {
            color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
            fontFamily: "Inter",
            display: "flex",
          },
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            backgroundColor: "#fff !important",
            color: "rgba(0, 0, 0, 0.87) !important",
            boxShadow:
              "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
            position: "relative !important",
            overflowY: "auto !important",
            display: "flex !important",
            flexDirection: "column !important",
            maxHeight: "506px !important",
            maxWidth: "707px",
            padding: "4px 42px",
          },
          "& .css-uhb5lp": {
            backgroundColor: "#fff !important",
            color: "rgba(0, 0, 0, 0.87) !important",
            boxShadow:
              "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
            position: "relative !important",
            overflowY: "auto !important",
            display: "flex !important",
            flexDirection: "column !important",
            maxHeight: "506px !important",
            maxWidth: "707px",
            padding: "4px 42px",
          },
        }}
      >
        <DialogTitle
          sx={{
            marginTop: "20px",
            fontFamily: "Inter",
            fontSize: "24px",
            fontWeight: 700,
            color: "#1C1B1F",
            lineHeight: "normal",
            fontStyle: "normal",
          }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: "absolute",
              right: "67px",
              top: "40px",
              width: " 24px",
              height: " 24px",
              padding: "5px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              {fields.map((field, index) => (
                <Grid
                  item
                  xs={index === 0 || index === 1 || index === 2 ? 12 : 6}
                  key={field.label}
                >
                  {field.type === "textarea" ? (
                    <Autocomplete
                      options={dropdowndata}
                      value={selectedProduct}
                      onChange={handleProductChange}
                      id="controllable-states-demo"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        "& label.Mui-focused": {
                          color: "#1A504C",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "green",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#1A504C!important",
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Product Name and Packing"
                          fullWidth
                        />
                      )}
                    />
                  ) : (
                    <>
                      {field.label === "Expiry Date" ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={field.label}
                            value={isEditModal ? expiryDate1 : null}
                            onChange={handleExpiryDateChange}
                            minDate={new Date()}
                            sx={{
                              width: "100%",
                              "& .MuiFormControl-root": {
                                width: "100%",
                              },
                              "& label.Mui-focused": {
                                color: "#1A504C",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "green",
                              },
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#1A504C!important",
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      ) : (
                        <TextField
                          fullWidth
                          label={field.label}
                          variant="outlined"
                          multiline={field.label === "Address"}
                          value={
                            (formData as { [key: string]: string })[field.label]
                          }
                          error={!!fieldErrors[field.label]}
                          helperText={fieldErrors[field.label]}
                          onChange={(e) =>
                            handleFieldChange(field.label, e.target.value)
                          }
                          onBlur={() => handleFieldBlur(field.label)}
                          sx={{
                            "& label.Mui-focused": {
                              color: "#1A504C",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "green",
                            },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#1A504C!important",
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: "#1A504C",
                            },
                          }}
                        />
                      )}
                    </>
                  )}
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PheonixButton
            onClick={onClose}
            style={{
              backgroundColor: "#165D59",
              fontWeight: 500,
              textTransform: "uppercase",
              fontSize: "14px",
              fontFamily: "Inter",
              marginBottom: "20px",
              marginRight: "15px",
              padding: "6px 16px",
              width: "76px",
              height: "36px",
            }}
          >
            {close}
          </PheonixButton>
          <PheonixButton
            onClick={handleConfirm}
            disabled={isButtonDisabled || isConfirmDisabled}
            style={{
              backgroundColor:
                isButtonDisabled || isConfirmDisabled ? "lightgray" : "#165D59",
              fontWeight: 500,
              textTransform: "uppercase",
              fontSize: "14px",
              fontFamily: "Inter",
              marginBottom: "20px",
              marginRight: "15px",
              padding: "6px 16px",
              width: "76px",
              height: "36px",
            }}
          >
            {confirmButtonText}
          </PheonixButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default StockModal;
