import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Line from "../../assets/Line.png";
import Icon from "../../assets/Icon .png";
import Downline from "../../assets/Downline.png";
import Line2 from "../../assets/Line2.png";
import { Sales_TEXT } from "../../constants";
import { Purchase_TEXT } from "../../constants";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";

interface SalesPurchaseCardProps {
  graphdata: number[];
  count: number;
}
const SalesPurchaseCard: React.FC<SalesPurchaseCardProps> = ({
  graphdata,
  count,
}) => {
  return (
    <Card
      style={{
        width: "380px",
        height: "170px",
        marginLeft: "0px",
        marginTop: '20px',
        border: "1px solid #0000001F",
        boxShadow: "0 2px 4px rgba(0,0,0,0.7)",
      }}
    >
      <CardContent>
        <Box>
          <div >
            <Typography
              variant="subtitle1"
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Sales Performance
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              in the last 7 days
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                marginTop: "0%",
                marginLeft: "6%",
                textAlign: "center",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "700",
              }}
            >
              {count}
            </Typography>
            <SparkLineChart data={graphdata ? graphdata : [0]} height={50} />
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SalesPurchaseCard;
