import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import PheonixSideBarProps from "../interfaces/PheonixSideBarProps";

const PheonixSideBar: React.FC<PheonixSideBarProps> = ({
  icon,
  text,
  to,
  onClick = () => {},
}) => {
  const location = useLocation();
  return (
    <ListItem
      sx={{
        textDecoration: "none",
        height: "48px",
        backgroundColor:
          location.pathname === to ? "rgba(0, 0, 0, 0.3)" : "transparent",
        color: "inherit",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          textDecoration: "none",
          height: "48px",
          width: "217px",
          marginLeft: "-1px",
        },
        fontSize: "20px",
        "& .MuiTypography-root": {
          fontSize: "16px",
          fontWeight: "700",
        },
      }}
      component={Link}
      to={to}
      onClick={onClick}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};
export default PheonixSideBar;
