import React from "react";
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Box,
} from "@mui/material";

interface ProductListCardProps {
  items: { name: string; quantity: number }[];
}

const ProductList: React.FC<ProductListCardProps> = ({ items }) => {
  return (
    <Card
      style={{
        width: "386px",
        height: "460px",
        marginTop: "15px",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "30px",
        gap: "12px",
        padding: "4px,42px,4px,42px",
        border: "1px solid #0000001F",
        boxShadow: "0 2px 4px rgba(0,0,0,0.7)",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          display: "flex",
          marginLeft: "14px",
          marginTop: "3%",
          fontSize: "20px",
          fontFamily: "Inter",
          fontWeight: "600",
        }}
      >
        Product stock
      </Typography>
      <CardContent style={{ padding: 0 }}>
        <Box sx={{ overflowY: "auto", maxHeight: "440px" }}>
          <List>
            {items.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem style={{ margin: "10px 0" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flex: 1,
                    }}
                  >
                    <ListItemText
                      style={{
                        fontSize: "14px",
                        fontFamily: "Inter",
                        fontWeight: "400",
                      }}
                      primary={item.name}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#000000",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                    }}
                  >
                    <ListItemText
                      style={{
                        fontSize: "14px",
                        fontFamily: "Inter",
                        fontWeight: "400",
                        color: "#000000",
                      }}
                      secondary={item.quantity}
                    />
                  </div>
                </ListItem>
                <Divider variant="middle" />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductList;
