import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/Analytics";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { useTheme } from "@mui/material/styles";
import SidebarItem from "../components/PheonixSideMenu";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Groups3OutlinedIcon from "@mui/icons-material/Groups3Outlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SidebarProps from "../interfaces/SidebarpRops";
import { Tooltip } from "@mui/material";
import { useSession } from "../Contexts/SessionContext";
const Sidebar: React.FC<SidebarProps> = ({ isOpen, onToggle }) => {
  const theme = useTheme();
  const handleMenuClick = () => {
    onToggle();
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const { isLoggedIn, logout } = useSession();
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogoutClick = () => {
    try {
      navigate("/", { replace: true });
      logout();
      localStorage.removeItem("userId");
    } catch (error) {
      console.error("Error occurred during logout:", error);
    }
  };
  const iconComponent = isOpen ? (
    <ChevronLeftIcon
      style={{ width: "28px", height: "28px", marginTop: "-6px" }}
    />
  ) : (
    <MenuIcon style={{ marginLeft: "-4px", marginTop: "-6px" }} />
  );
  const menuItems = [
    {
      text: "Home",
      icon: isOpen ? (
        <HomeOutlinedIcon sx={{ color: "white" }} />
      ) : (
        <Tooltip title="Home">
          <HomeOutlinedIcon sx={{ color: "white" }} />
        </Tooltip>
      ),
      to: "/dashboard",
    },
    {
      text: "Customer",
      icon: isOpen ? (
        <Groups2OutlinedIcon sx={{ color: "white" }} />
      ) : (
        <Tooltip title="Customer">
          <Groups2OutlinedIcon sx={{ color: "white" }} />
        </Tooltip>
      ),
      to: "/customer",
    },
    {
      text: "Supplier",
      icon: isOpen ? (
        <Groups3OutlinedIcon sx={{ color: "white" }} />
      ) : (
        <Tooltip title="Supplier">
          <Groups3OutlinedIcon sx={{ color: "white" }} />
        </Tooltip>
      ),
      to: "/supplier",
    },
    {
      text: "Product",
      icon: isOpen ? (
        <CategoryOutlinedIcon sx={{ color: "white" }} />
      ) : (
        <Tooltip title="Product">
          <CategoryOutlinedIcon sx={{ color: "white" }} />
        </Tooltip>
      ),
      to: "/product",
    },
    {
      text: "Stock",
      icon: isOpen ? (
        <Inventory2OutlinedIcon sx={{ color: "white" }} />
      ) : (
        <Tooltip title="Stock">
          <Inventory2OutlinedIcon sx={{ color: "white" }} />
        </Tooltip>
      ),
      to: "/stock",
    },
    {
      text: "Invoice",
      icon: isOpen ? (
        <ReceiptOutlinedIcon sx={{ color: "white" }} />
      ) : (
        <Tooltip title="Invoice">
          <ReceiptOutlinedIcon sx={{ color: "white" }} />
        </Tooltip>
      ),
      to: "/invoice",
    },
    {
      text: "Logout",
      icon: isOpen ? (
        <LogoutOutlinedIcon sx={{ color: "white" }} />
      ) : (
        <Tooltip title="LogOut">
          <LogoutOutlinedIcon sx={{ color: "white" }} />
        </Tooltip>
      ),
      onClick: handleLogoutClick,
    },
  ].map((item) => ({
    ...item,
    onClick: item.onClick || (() => {}),
  }));

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isOpen ? 217 : 65,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          backgroundColor: "#1A504C",
          fontSize: "16px",
          color: "white",
          width: isOpen ? 217 : 65,
          boxSizing: "border-box",
          transition: "width 0.2s ease-in-out, visibility 0.2s linear",
          overflow: "hidden",
        },
      }}
      open={isOpen}
      onClose={onToggle}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div
        onClick={handleMenuClick}
        style={
          isOpen
            ? {
                position: "absolute",
                top: "27px",
                right: "20px",
                cursor: "pointer",
              }
            : {
                position: "absolute",
                top: "27px",
                left: "20px",
                cursor: "pointer",
              }
        }
      >
        {iconComponent}
      </div>
      <List sx={{ top: "85px", left: "0px" }}>
        {menuItems.map((item, index) => (
          <SidebarItem
            key={index}
            icon={item.icon}
            text={item.text}
            to={item.to || ""}
            onClick={item.onClick}
          />
        ))}
      </List>
    </Drawer>
  );
};
export default Sidebar;
