import React, { useState, useEffect, ReactNode } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery, useMutation } from "@apollo/client";
import { ERROR_MESSAGES, INVOICE_NUMBER } from "../constants";
import InvoiceModalProps from "../interfaces/InvoiceModalProps";
import PheonixInvoiceTable from "./PheonixInvoiceTable";
import { RowData, TableColumn } from "../interfaces/PheonixInvoiceTableProps";
import PheonixPurchaseTable from "./PheonixPurchaseTable";
import PheonixButton from "./PheonixButton";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Autocomplete,
} from "@mui/material";
import {
  GET_CUSTOMERS,
  GET_SUPPLIERS,
  GET_ALL_CUSTOMERS,
  GET_ALL_SUPPLIERS,
} from "../graphql/queries";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomerProps from "../interfaces/CustomerProps";
import SupplierProps from "../interfaces/SupplierProps";
interface StockProps {
  [key: string]: any;
}
const InvoiceModal = <T extends object>({
  open,
  onClose,
  title,
  fields,
  onConfirm,
  close,
  confirmButtonText,
  formData,
  setFormData,
  isEditModal,
  errorMessage,
  onBlur,
  onFocus,
  disabled,
  value,
  ...rest
}: InvoiceModalProps<T>) => {
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isAllRowComplete,setIsALLRowComplete]= useState<boolean>(true);

  const [customersData, setCustomersData] = useState<
    { id: string; name: string }[]
  >([]);
  const [supplierData, setSupplierData] = useState<
    { id: string; name: string }[]
  >([]);
  const [customerName, setCustomerName] = useState<
    { id: string; name: string }[]
  >([]);

  const [addressLines, setAddressLines] = useState<number>(1);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [tax, setTax] = useState<number | null>(0);
  const [discount, setDiscount] = useState<number | null>(0);
  const [sgst, setSgst] = useState<number | null>(0);
  const [rows, setRows] = useState<RowData[]>([
    {
      sNo: 1,
      customerName: "",
      invoiceNo: "",
      MRP: "",
      ExpiryDate: "",
      Quantity: "",
      GST: "",
      Amount: "",
    },
  ]);
  const [quantityErrors, setQuantityErrors] = useState<Array<string>>([""]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const limit = rowsPerPage;
  const [invoiceNetAmount, setInvoiceNetAmount] = useState<number | null>(null);
  const [invoicetotal, setInvoiceTotal] = useState<number | null>(null);
  const [cgst, setCgst] = useState<number | null>(null);
  const [igst, setIgst] = useState<number | null>(null);
  const [totItems, setTotItems] = useState<number | null>(null);
  const [totQuantity, setTotQuantity] = useState<number | null>(null);
  const [selectedValue, setSelectedValue] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [tableData, setTableData] = useState<CustomerProps[]>([]);

  const {
    loading: customersLoading,
    error: customersError,
    data: customers,
    refetch: refetchCustomers,
  } = useQuery(GET_ALL_CUSTOMERS, {
    onCompleted: (data) => {
      setCustomersData(
        data.getCustomerList.map((stock: CustomerProps) => ({
          id: stock.id,
          name: stock.customer_name,
        }))
      );
    },
  });

  const {
    loading: suppliersLoading,
    error: suppliersError,
    data: suppliersData,
    refetch: refetchSuppliers,
  } = useQuery(GET_ALL_SUPPLIERS, {
    onCompleted: (data) => {
      setSupplierData(
        data.getSupplierList.map((stock: SupplierProps) => ({
          id: stock.id,
          name: stock.supplier_name,
        }))
      );
    },
  });

  useEffect(() => {
    if (!customersLoading && !customersError && customers) {
      setCustomersData(
        customers.getCustomerList.map((stock: CustomerProps) => ({
          id: stock.id,
          name: stock.customer_name,
        }))
      );
    }
    if (!suppliersLoading && !suppliersError && suppliersData) {
      setSupplierData(
        suppliersData.getSupplierList.map((stock: SupplierProps) => ({
          id: stock.id,
          name: stock.supplier_name,
        }))
      );
    }
  }, [
    customers,
    customersLoading,
    customersError,
    suppliersData,
    suppliersError,
    suppliersLoading,
  ]);

  const mapStockToRowData = (stock1: StockProps, index: any): RowData => {
    const {
      product_id,
      product_name,
      batch_no,
      mrp,
      ptr,
      gst,
      expiry_date,
      amount,
      quantity,
      stock,
    } = stock1;

    return {
      product_id,
      ProductName: product_name,
      BatchNumber: batch_no,
      MRP: mrp,
      PTR: ptr,
      ExpiryDate: expiry_date,
      GST: gst,
      Amount: amount,
      Quantity: quantity,
      available: stock,
    };
  };

  useEffect(() => {
    const mappedRows = formData.flatMap((row) =>
      row.products.map((stock: any, index: number) =>
        mapStockToRowData(stock, index)
      )
    );
    setRows(mappedRows);
  }, [open]);

  useEffect(() => {
    if (value === "1") {
      const customer = customersData?.find(
        (customer) => customer.name === formData[0].customer_name
      );
      if (customer) {
        setCustomerName([customer]);
        setSelectedValue(customer || null);
      }
    } else {
      const supplier = supplierData?.find(
        (supplier: any) => supplier.name === formData[0].supplier_name
      );
      if (supplier) {
        setCustomerName([supplier]);
        setSelectedValue(supplier || null);
      }
    }
    if (formData.length > 0) {
      setTotalAmount(formData[0].total_amount);
      setCgst(formData[0].cgst);
      setInvoiceNetAmount(formData[0].net_amount);
      setTax(formData[0].tax);
      setDiscount(formData[0].discount);
      setIgst(formData[0].igst);
    }
  }, [open, value]);

  const handleAddRow = () => {
    const newRow = {
      sNo: rows.length + 1,
      ProductName: "",
      BatchNumber: "",
      MRP: "",
      ExpiryDate: "",
      Quantity: "",
      GST: "",
      Amount: "",
    };
    setRows([...rows, newRow]);
  };
  const handleDeleteRow = (index: number) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const columns = [
    {
      field: "ProductName",
      headerName: "Product Name",
      width: "20%",
      padding: "16px",
    },
    {
      field: "BatchNumber",
      headerName: "Batch No",
      width: "12%",
      padding: "9px",
    },
    {
      field: "MRP",
      headerName: "MRP",
      width: "10%",
      padding: "17px",
    },
    {
      field: "PTR",
      headerName: value === "1" ? "PTR" : "Retail Margin",
      width: "12%",
      padding: "12px",
    },
    {
      field: "ExpiryDate",
      headerName: "Expiry Date",
      width: "12%",
      padding: "2px",
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      width: "11%",
      padding: "0px",
    },
    {
      field: "GST",
      headerName: "GST",
      width: "10%",
      padding: "0px",
    },
    {
      field: "Amount",
      headerName: "Amount",
      width: "10%",
      padding: "8px",
    },
    {
      field: "Action",
      headerName: "Action",
      width: "8%",
      padding: "14px",
      render: (index: number) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => handleDeleteRow(index)}
            style={{ color: "#D32F2F" }}
          >
            <RemoveCircleIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const isRowComplete = (row: any) => {
    for (const column of columns) {
      if (column.field !== "Action" && !row[column.field]) {
        return false;
      }
    }
    return true;
  };

  const isTableComplete = () => {
    for (const row of rows) {
      if (!isRowComplete(row)) {
        return false;
      }
    }
    return true;
  };

  const handleNetAmountChange = (
    newNetAmount: number | null,
    cgst: number | 0,
    items: number | null,
    quantity: number | null,
    total: number | null,
    tax: number | null,
    igst: number | null,
    discount: number | null
  ) => {
    setInvoiceNetAmount(newNetAmount);
    setInvoiceTotal(total);
    setCgst(cgst);
    setTotItems(items);
    setTotQuantity(quantity);
    setTax(tax);
    setSgst(cgst);
    setIgst(igst);
    setDiscount(discount);
  };

  const handleInputChange = (
    index: number,
    fieldName: keyof (typeof rows)[0],
    value: string
  ) => {
    const newRows = rows.map((row, rowIndex) => {
      if (index === rowIndex) {
        return { ...row, [fieldName]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  useEffect(() => {
    const hasCompleteRow = isTableComplete();
    setIsALLRowComplete(hasCompleteRow);

  }, [rows]);

  useEffect(() => {
    if (
      value === "1" &&
      isAllRowComplete &&
      customerName &&
      Object.keys(customerName).length > 0 &&
      quantityErrors.every((error) => error === "" )
    ) {
      setIsButtonDisabled(false);
    } else if (
      value === "2" &&
      customerName &&
      Object.keys(customerName).length > 0 &&
      isAllRowComplete &&
      invoiceNetAmount !== null
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [rows, customerName, quantityErrors, handleNetAmountChange,isAllRowComplete]);

  const handleInputChange1 = (
    index: number,
    field: string,
    value: string,
    available: string
  ) => {
    const updatedRows: RowData[] = rows.map((row, i) => {
      if (i === index) {
        const updatedRow = { ...row, [field]: value };

        if (value === null || value.trim() === "") {
          updatedRow["Amount"] = 0;
          setQuantityErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            newErrors[index] = "";
            return newErrors;
          });
        } else {
          const quantity = parseInt(value) || 0;
          const ptr = parseFloat(updatedRow["PTR"]) || 0;
          updatedRow["Amount"] = (quantity * ptr).toFixed(2);
          if (quantity > parseInt(available, 10)) {
            setQuantityErrors((prevErrors) => {
              const newErrors = [...prevErrors];
              newErrors[index] = ERROR_MESSAGES.MORE_QUANTITY;
              return newErrors;
            });
          } else {
            setQuantityErrors((prevErrors) => {
              const newErrors = [...prevErrors];
              newErrors[index] = "";
              return newErrors;
            });
          }
        }

        return updatedRow;
      }
      return row;
    });

    setRows(updatedRows);
  };

  const handleInvoiceTableChange = (updatedRows: RowData, index: number) => {
    const newRows = [...rows];
    newRows[index] = updatedRows;
    setRows(newRows);
  };

  const handleFieldChange = (fieldLabel: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [fieldLabel]: value }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
  };

  const handleNameChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setCustomerName(newValue);
  };
  const handleConfirm = () => {
    onConfirm(
      customerName,
      invoiceNetAmount,
      cgst,
      tax,
      totItems,
      totQuantity,
      invoicetotal,
      rows,
      igst,
      discount
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialogTitle-root": {
          color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
          fontFamily: "Inter",
          display: "flex",
        },
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          backgroundColor: "#fff !important",
          color: "rgba(0, 0, 0, 0.87) !important",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
          position: "relative !important",
          overflowY: "auto !important",
          display: "flex !important",
          flexDirection: "column !important",
          height: "754px !important",
          maxWidth: "1090px",
          padding: "4px 42px",
        },
        "& .css-uhb5lp": {
          backgroundColor: "#fff !important",
          color: "rgba(0, 0, 0, 0.87) !important",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
          position: "relative !important",
          overflowY: "auto !important",
          display: "flex !important",
          flexDirection: "column !important",
          height: "754px !important",
          maxWidth: "1090px",
          padding: "4px 42px",
        },
      }}
    >
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          padding: "40px 38px 0px 69px",
          color: "black",
        }}
      >
        <div>
          <span
            style={{
              fontSize: "16px",
              fontWeight: "700",
              fontFamily: "Roboto",
              color: "black",
            }}
          ></span>
        </div>
      </div>

      <DialogContent>
        <DialogContent>
          <DialogContentText>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                padding: "20px 48px 0px 19px",
                color: "black",
              }}
            >
              <div style={{ marginRight: "50px" }}>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    fontFamily: "inter",
                    color: "black",
                    marginLeft: "0px",
                  }}
                >
                  {INVOICE_NUMBER}:#{formData[0]?.invoice_no}
                </span>
              </div>
              <div>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    fontFamily: "inter",
                    color: "black",
                  }}
                >
                  {ERROR_MESSAGES.INVOICEDATE}:{formData[0]?.invoice_date}
                </span>
              </div>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    padding: "20px 0px 0px 5px",
                    marginTop: "15px",
                  }}
                >
                  <TableCell
                    style={{
                      border: "none",
                      fontSize: "16px",
                      fontWeight: "700",
                      fontFamily: "Inter",
                      color: "black",
                    }}
                  >
                    {title}
                  </TableCell>
                  <Autocomplete
                    id="combo-box-demo"
                    options={value === "1" ? customersData : supplierData}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) =>
                      handleNameChange(event, newValue)
                    }
                    value={selectedValue}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          value === "1"
                            ? ERROR_MESSAGES.CUSTOMER_NAME1
                            : ERROR_MESSAGES.SUPPLIER_NAME1
                        }
                      />
                    )}
                    style={{ width: "30%" }}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#1A504C",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "green",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#1A504C!important",
                        },
                      },
                    }}
                  />
                </div>
                <TableContainer style={{ marginTop: "24px" }}>
                  <Table>
                    <div>
                      {value === "1" ? (
                        <PheonixInvoiceTable
                          rows={rows}
                          columns={columns}
                          handleInputChange={handleInputChange1}
                          handleInvoiceTableChange={handleInvoiceTableChange}
                          quantityErrors={quantityErrors}
                          handleNetAmountChange={handleNetAmountChange}
                          handleAddRow={handleAddRow}
                          editDiscount={discount}
                          editCgst={cgst}
                          editIgst={igst}
                        />
                      ) : (
                        <PheonixPurchaseTable
                          rows={rows}
                          columns={columns}
                          handleInputChange={handleInputChange}
                          handleInvoiceTableChange={handleInvoiceTableChange}
                          quantityErrors={quantityErrors}
                          handleNetAmountChange={handleNetAmountChange}
                          totalEditAmount={totalAmount}
                          totalCgst={cgst}
                          totalNet={invoiceNetAmount}
                          totalEditTax={tax}
                          handleAddRow={handleAddRow}
                        />
                      )}
                    </div>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <PheonixButton
          onClick={onClose}
          style={{
            backgroundColor: "#165D59",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            fontFamily: "Inter",
            marginBottom: "15px",
            marginRight: "30px",
            padding: "6px 16px",
            width: "146px",
            height: "36px",
          }}
        >
          {ERROR_MESSAGES.CANCEL}
        </PheonixButton>
        <PheonixButton
          onClick={handleConfirm}
          style={{
            backgroundColor: isButtonDisabled ? "lightgray" : "#165D59",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            fontFamily: "Inter",
            marginBottom: "15px",
            marginRight: "15px",
            padding: "6px 16px",
            width: "176px",
            height: "36px",
          }}
        >
          {confirmButtonText}
        </PheonixButton>
      </DialogActions>
    </Dialog>
  );
};
export default InvoiceModal;
