import React, { useState, useEffect, ChangeEvent } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CUSTOMERS, SEARCH_CUSTOMERS } from "../graphql/queries";
import PheonixTable from "../components/PheonixTable";
import { useTheme, IconButton } from "@mui/material";
import PheonixSearchComponent from "../components/PheonixSearchComponent";
import PheonixButton from "../components/PheonixButton";
import styled from "styled-components";
import PheonixModal from "../components/PheonixModal";
import {
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  EDIT_CUSTOMER,
} from "../graphql/mutations";
import PheonixSnackbar from "../components/PheonixSnackbar";
import PheonixSnackbarProps from "../interfaces/PheonixSnackbarProps";
import { ERROR_MESSAGES } from "../constants";
import CustomerProps from "../interfaces/CustomerProps";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../components/DeleteModal";
import PheonixPaper from "../components/PheonixPaper";
const StyledDiv = styled.div`
  padding: 4px 42px 4px 50px;
  overflowx: hidden;
  @media (max-width: 707px) {
    padding: 4px 42px 4px 20px;
  }
`;
const CustomerList: React.FC = ({}) => {
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [tableData, setTableData] = useState<CustomerProps[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [customerFormData, setCustomerFormData] = useState({
    "Customer Name": "",
    "Phone Number": "",
    Email: "",
    Address: "",
  });
  const [filteredCustomersData, setFilteredCustomersData] = useState<
    CustomerProps[]
  >([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [nameerrorMessage, setNameErrorMessage] = useState("");
  const [addresserrorMessage, setAddressErrorMessage] = useState("");
  const [phonnoerrorMessage, setPhonenoErrorMessage] = useState("");
  const [email, setemail] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [customerIdToDelete, setCustomerIdToDelete] = useState<string>("");
  const [iscustomerButtonDisabled, setIscustomerButtonDisabled] =
    useState(true);
  const [addCustomerMutation] = useMutation(ADD_CUSTOMER);
  const [deleteCustomerMutation] = useMutation(DELETE_CUSTOMER);
  const [editcustomerMutation] = useMutation(EDIT_CUSTOMER);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const limit = rowsPerPage;
  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: customerRefetch,
  } = useQuery(GET_CUSTOMERS, {
    variables: {
      limit,
      offset: (currentPage - 1) * limit,
    },
    onCompleted: (data) => {
      setTableData(data.getAllCustomers.customers);
      setTableCount(data.getAllCustomers.totalCount);
      setTotalPages(Math.ceil(data.getAllCustomers.totalCount / limit));
    },
  });

  const {
    loading: searchLoading,
    error: searchError,
    data: searchData,
    refetch: searchRefetch,
  } = useQuery(SEARCH_CUSTOMERS, {
    variables: {
      searchQuery: searchQuery,
      limit,
      offset: (currentPage - 1) * limit,
    },
    onCompleted: (data) => {
      setFilteredCustomersData(data.searchCustomers.customers);
      setTableCount(data.searchCustomers.totalCount);
      setTotalPages(Math.ceil(data.searchCustomers.totalCount / limit));
    },
  });
  useEffect(() => {
    if (!customerLoading && !customerError && customerData) {
      setTableData(customerData.getAllCustomers.customers);
      setTableCount(customerData.getAllCustomers.totalCount);
      setTotalPages(Math.ceil(customerData.getAllCustomers.totalCount / limit));
    }
  }, [customerData, customerLoading, customerError]);

  useEffect(() => {
    customerRefetch();
  }, []);

  useEffect(() => {
    if (!searchLoading && !searchError && searchData) {
      setFilteredCustomersData(searchData.searchCustomers.customers);
      setTableCount(searchData.searchCustomers.totalCount);
      setTotalPages(Math.ceil(searchData.searchCustomers.totalCount / limit));
    }
  }, [searchData, searchLoading, searchError, searchQuery]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (page > currentPage) {
      setCurrentPage(currentPage + 1);
    } else if (page < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  const updateFormEmptyState = () => {
    const isEmpty = Object.values(customerFormData).some(
      (value) => value.trim() === ""
    );
    setIsFormEmpty(isEmpty);
  };
  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };
  const handleOpenModal = () => {
    setIsEditModal(false);
    setCustomerFormData({
      "Customer Name": "",
      "Phone Number": "",
      Email: "",
      Address: "",
    });
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setCustomerFormData({
      "Customer Name": "",
      "Phone Number": "",
      Email: "",
      Address: "",
    });
    setEmailError("");
    setErrorMessage("");
    setAddressErrorMessage("");
    setNameErrorMessage("");
    setIsModalOpen(false);
  };
  const fields = [
    { label: "Customer Name", type: "text" },
    { label: "Phone Number", type: "number" },
    { label: "Email", type: "text" },
    { label: "Address", type: "text" },
  ];
  const [editCustomerId, setEditCustomerId] = useState<string>("");
  const handleEditClick = (customerId: string) => {
    const customer = tableData.find((customer) => customer.id === customerId);
    if (customer) {
      setEditCustomerId(customerId);
      setCustomerFormData({
        "Customer Name": customer.customer_name,
        "Phone Number": customer.phone_no,
        Email: customer.email,
        Address: customer.address,
      });
      setIsEditModal(true);
      setIsModalOpen(true);
    }
  };
  const handleemailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setemail(e.target.value);
  };
  const handleEmailFocus = () => {
    setErrorMessage("");
  };
  const handleEmailBlur = () => {
    const emailRegex = ERROR_MESSAGES.EMAIL_REG;
    if (!emailRegex.test(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
    } else {
      setErrorMessage("");
    }
  };
  function isValidEmail(email: string) {
    const emailRegex = ERROR_MESSAGES.EMAIL_REG;
    return emailRegex.test(email);
  }
  const handlePhoneKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!(e.key >= "0" && e.key <= "9") && e.key !== "Backspace") {
      e.preventDefault();
    }
  };
  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setPhoneNo(numericValue);
  };
  const handlePhoneBlur = () => {
    const phoneRegex = ERROR_MESSAGES.PHONE_REG;
    if (!phoneRegex.test(phone_no)) {
      setPhonenoErrorMessage(ERROR_MESSAGES.VALID_PHONE);
    } else {
      setPhonenoErrorMessage("");
    }
  };
  function isValidPhoneNumber(phone_no: string) {
    const phoneRegex = ERROR_MESSAGES.PHONE_REG;
    return phoneRegex.test(phone_no);
  }
  const [emailError, setEmailError] = useState("");
  const handleConfirm = async () => {
    let errorMessage: string | null = null;
    switch (true) {
      case !customerFormData["Customer Name"]:
        setNameErrorMessage(ERROR_MESSAGES.CUSTOMER_NAME_VALIDATION);
        errorMessage = ERROR_MESSAGES.CUSTOMER_NAME_VALIDATION;
        break;
      case !isValidPhoneNumber(customerFormData["Phone Number"]):
        setPhonenoErrorMessage(ERROR_MESSAGES.VALID_PHONE);
        errorMessage = ERROR_MESSAGES.VALID_PHONE;
        break;
      case !isValidEmail(customerFormData["Email"]):
        setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
        errorMessage = ERROR_MESSAGES.VALID_EMAIL;
        break;
      case !customerFormData["Address"]:
        setAddressErrorMessage(ERROR_MESSAGES.SUPPLIER_ADDRESS);
        errorMessage = ERROR_MESSAGES.SUPPLIER_ADDRESS;
        break;
      default:
        break;
    }
    if (errorMessage !== null) {
      return;
    }
    try {
      let result;
      if (isEditModal) {
        const updateCustomer = tableData.find(
          (customer) => customer.id === editCustomerId
        );
        if (
          updateCustomer &&
          (updateCustomer.customer_name !== customerFormData["Customer Name"] ||
            updateCustomer.phone_no !== customerFormData["Phone Number"] ||
            updateCustomer.email !== customerFormData["Email"] ||
            updateCustomer.address !== customerFormData["Address"])
        ) {
          result = await editcustomerMutation({
            variables: {
              id: editCustomerId,
              customer_name: customerFormData["Customer Name"],
              phone_no: customerFormData["Phone Number"],
              email: customerFormData["Email"],
              address: customerFormData["Address"],
            },
          });
          const successMessage = result.data.editCustomer.message;
          if (successMessage) {
            setSnackbarMessage(successMessage);
            setSnackbarOpen(true);
            setIsEditModal(false);
            setIsModalOpen(false);
            handleCloseModal();
            customerRefetch();
          }
        } else {
          setSnackbarMessage(ERROR_MESSAGES.CUSTOMER_NOT_UPDATED);
          setSnackbarOpen(true);
          setIsEditModal(false);
          setIsModalOpen(false);
          handleCloseModal();
        }
      } else {
        result = await addCustomerMutation({
          variables: {
            customer_name: customerFormData["Customer Name"],
            phone_no: customerFormData["Phone Number"],
            email: customerFormData["Email"],
            address: customerFormData["Address"],
          },
        });
        if (
          result.data &&
          result.data.createCustomer &&
          result.data.createCustomer.id
        ) {
          const successMessage = result.data.createCustomer.message;
          setSnackbarMessage(successMessage);
          setSnackbarOpen(true);
          setIsEditModal(false);
          setIsModalOpen(false);
          handleCloseModal();
          customerRefetch();
        } else {
          const message = result.data.createCustomer.message;
          if (message.toLowerCase().includes("email")) {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              ["Email"]: message,
            }));
          } else if (message.toLowerCase().includes("number")) {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              ["Phone Number"]: message,
            }));
          }

          setErrorMessage(ERROR_MESSAGES.SERVER_ERR);
        }
      }
    } catch (error: any) {
      if (error.message.toLowerCase().includes("email")) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          ["Email"]: error.message,
        }));
      } else if (error.message.toLowerCase().includes("number")) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          ["Phone Number"]: error.message,
        }));
      }
    }
  };
  const handleDeleteClick = async (customerId: string) => {
    setCustomerIdToDelete(customerId);
    setOpenDeleteConfirmation(true);
  };
  const handleDeleteConfirmed = async () => {
    try {
      const result = await deleteCustomerMutation({
        variables: {
          id: customerIdToDelete,
        },
      });
      customerRefetch();
      setSnackbarOpen(true);
      setSnackbarMessage(ERROR_MESSAGES.DELETE_SUCCESS);
    } catch (error) {
      console.error("Error during deletion:", error);
    } finally {
      setOpenDeleteConfirmation(false);
    }
  };
  const columns = [
    { id: "serial_no", label: "S.No" },
    { id: "customer_code", label: "Customer Code" },
    { id: "customer_name", label: "Customer Name" },
    { id: "phone_no", label: "Phone Number" },
    { id: "email", label: "Email" },
    { id: "address", label: "Address" },
    { id: "sales", label: "No. of Sales" },
    {
      id: "actions",
      label: "Actions",
      align: "center",
      render: (row: CustomerProps) => {
        return (
          <div style={{ flexDirection: "row" }}>
            <IconButton
              onClick={() => handleEditClick(row.id)}
              style={{
                color: "#2E7D32",
                fontSize: "16px",
                outline: "none",
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteClick(row.id)}
              style={{
                fontSize: "16px",
                color: "#D32F2F",
                outline: "none",
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ width: "95%" }}>
      <PheonixSnackbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "40px",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        vertical="top"
        horizontal="center"
        contentProps={{ sx: { backgroundColor: "#1A504C" } }}
      />
      <StyledDiv>
        <PheonixPaper
          elevation={1}
          marginLeft={-20}
          marginRight={-30}
          height={100}
          marginTop={25}
          style={{
            border: 0,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            marginBottom: "25px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PheonixSearchComponent onChange={handleSearch} />
            <div style={{ marginRight: "30px" }}>
              <PheonixButton
                variant="contained"
                onClick={handleOpenModal}
                style={{
                  color: "white",
                  marginTop: "15px",
                  backgroundColor: "#165D59",
                  fontSize: "16px",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);",
                }}
              >
                {ERROR_MESSAGES.BUTTON_TITLE}
              </PheonixButton>
            </div>
          </div>
        </PheonixPaper>
        <PheonixTable
          columns={columns}
          data={
            searchQuery && filteredCustomersData
              ? filteredCustomersData
              : tableData
          }
          totalPages={tableCount}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          handlePageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledDiv>
      <PheonixModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={isEditModal ? "Edit Customer" : ERROR_MESSAGES.BUTTON_TITLE}
        fields={fields}
        onConfirm={handleConfirm}
        confirmButtonText={isEditModal ? "Save" : ERROR_MESSAGES.SAVE}
        formData={customerFormData}
        setFormData={setCustomerFormData}
        close={ERROR_MESSAGES.CLOSE}
        isEditModal={isEditModal}
        errorMessage={errorMessage}
        phonenoErrorMessage={phonnoerrorMessage}
        setErrorMessage={setErrorMessage}
        setPhonenoErrorMessage={setPhonenoErrorMessage}
        nameerrorMessage={nameerrorMessage}
        emailError={emailError}
        setNameErrorMessage={setNameErrorMessage}
        addresserrorMessage={addresserrorMessage}
        setAddressErrorMessage={setAddressErrorMessage}
        fieldErrors1={fieldErrors}
        setFieldErrors1={setFieldErrors}
        onBlur={handleEmailBlur}
        onFocus={handleEmailFocus}
        disabled={!iscustomerButtonDisabled}
        isFormEmpty={isFormEmpty}
        updateFormEmptyState={updateFormEmptyState}
      />
      <DeleteModal
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onConfirm={handleDeleteConfirmed}
        entity={ERROR_MESSAGES.CUSTOMER_NAME}
      />
    </div>
  );
};
export default CustomerList;
