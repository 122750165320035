import { gql } from "@apollo/client";

export const REGISTER_USER_MUTATION = gql`
  mutation RegisterUser($email: String!, $password: String!) {
    registerUsers(email: $email, password: $password) {
      user {
        id
        email
      }
      message
      url
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        email
      }
      message
    }
  }
`;
export const ADD_CUSTOMER = gql`
  mutation createCustomer(
    $customer_name: String!
    $phone_no: String!
    $email: String!
    $address: String!
  ) {
    createCustomer(
      customer_name: $customer_name
      phone_no: $phone_no
      email: $email
      address: $address
    ) {
      id
      message
    }
  }
`;

export const EDIT_CUSTOMER = gql`
  mutation EditCustomer(
    $id: ID!
    $customer_name: String
    $email: String
    $phone_no: String
    $address: String
  ) {
    editCustomer(
      id: $id
      customer_name: $customer_name
      email: $email
      phone_no: $phone_no
      address: $address
    ) {
      id
      message
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      id
      message
    }
  }
`;
export const ADD_SUPPLIER = gql`
  mutation CreateSupplier(
    $supplier_name: String!
    $registration_no: String!
    $phone_no: String!
    $email: String!
    $address: String!
  ) {
    createSupplier(
      supplier_name: $supplier_name
      registration_no: $registration_no
      phone_no: $phone_no
      email: $email
      address: $address
    ) {
      id
      message
    }
  }
`;
export const EDIT_SUPPLIER = gql`
  mutation EditSupplier(
    $id: ID!
    $supplier_name: String
    $registration_no: String
    $phone_no: String
    $email: String
    $address: String
  ) {
    editSupplier(
      id: $id
      supplier_name: $supplier_name
      registration_no: $registration_no
      phone_no: $phone_no
      email: $email
      address: $address
    ) {
      id
      message
    }
  }
`;
export const DELETE_SUPPLIER = gql`
  mutation DeleteSupplier($id: ID!) {
    deleteSupplier(id: $id) {
      id
      message
    }
  }
`;

export const ADD_PRODUCT = gql`
  mutation CreateProducts(
    $product_name_packing: String!
    $gst: String!
    $retail_margin: String!
  ) {
    createProducts(
      product_name_packing: $product_name_packing
      gst: $gst
      retail_margin: $retail_margin
    ) {
      id
      message
    }
  }
`;

export const EDIT_PRODUCT = gql`
  mutation EditProduct(
    $id: ID!
    $product_name_packing: String
    $gst: String!
    $retail_margin: String!
  ) {
    editProduct(
      id: $id
      product_name_packing: $product_name_packing
      gst: $gst
      retail_margin: $retail_margin
    ) {
      id
      message
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      id
      message
    }
  }
`;
export const ADD_SALES_INVOICE = gql`
  mutation CreateSales(
    $customer_name: String!
    $customer_id: String!
    $products: [ProductInput]!
    $total_item: String!
    $total_quantity: String!
    $total_amount: String!
    $net_amount: String!
    $tax: String
    $discount: String
    $cgst: String
    $sgst: String
    $igst: String
  ) {
    createSales(
      customer_name: $customer_name
      customer_id: $customer_id
      products: $products
      total_item: $total_item
      total_quantity: $total_quantity
      total_amount: $total_amount
      net_amount: $net_amount
      tax: $tax
      discount: $discount
      cgst: $cgst
      sgst: $sgst
      igst: $igst
    ) {
      message
      sales {
        invoice_no
      }
    }
  }
`;

export const ADD_PURCHASE_INVOICE = gql`
  mutation CreatePurchase(
    $products: [ProductInput]!
    $supplier_name: String!
    $supplier_id: String!
    $total_item: String
    $total_quantity: String
    $total_amount: String
    $cgst: String
    $sgst: String
    $discount: String
    $igst: String
    $tax: String
    $net_amount: String!
  ) {
    createPurchase(
      products: $products
      supplier_name: $supplier_name
      supplier_id: $supplier_id
      total_item: $total_item
      total_quantity: $total_quantity
      total_amount: $total_amount
      cgst: $cgst
      sgst: $sgst
      igst: $igst
      discount: $discount
      tax: $tax
      net_amount: $net_amount
    ) {
      message
      purchase {
        invoice_no
      }
    }
  }
`;

export const EDIT_STOCK = gql`
  mutation EditStock(
    $id: ID!
    $product_name: String
    $batch_no: String
    $mrp: String
    $expiry_date: String
    $quantity: String
  ) {
    editStock(
      id: $id
      product_name: $product_name
      batch_no: $batch_no
      mrp: $mrp
      expiry_date: $expiry_date
      quantity: $quantity
    ) {
      id
      message
    }
  }
`;

export const DELETE_STOCK = gql`
  mutation DeleteStock($id: ID!) {
    deleteStock(id: $id) {
      id
      message
    }
  }
`;

export const ADD_STOCK = gql`
  mutation CreateStock(
    $product_name: String!
    $batch_no: String!
    $mrp: String!
    $expiry_date: String!
    $quantity: String!
  ) {
    createStock(
      product_name: $product_name
      expiry_date: $expiry_date
      batch_no: $batch_no
      mrp: $mrp
      quantity: $quantity
    ) {
      id
      message
      stock {
        id
        serial_no
        stock_code
        product_name
        batch_no
        mrp
        ptramt
        gst
        expiry_date
        quantity
      }
    }
  }
`;

export const DELETE_SALES = gql`
  mutation DeleteSales($id: ID!) {
    deleteSales(id: $id) {
      message
    }
  }
`;

export const DELETE_PURCHASE = gql`
  mutation DeletePurchase($id: ID!) {
    deletePurchase(id: $id) {
      message
    }
  }
`;

export const EDIT_SALES_INVOICE = gql`
  mutation EditSales(
    $id: ID!
    $customer_name: String!
    $customer_id: String!
    $products: [ProductInput]!
    $total_item: String!
    $total_quantity: String!
    $total_amount: String!
    $net_amount: String!
    $tax: String
    $discount: String
    $cgst: String
    $sgst: String
    $igst: String
  ) {
    editSales(
      id: $id
      customer_name: $customer_name
      customer_id: $customer_id
      products: $products
      total_item: $total_item
      total_quantity: $total_quantity
      total_amount: $total_amount
      net_amount: $net_amount
      tax: $tax
      discount: $discount
      cgst: $cgst
      sgst: $sgst
      igst: $igst
    ) {
      message
    }
  }
`;

export const EDIT_PURCHASE_INVOICE = gql`
  mutation EditPurchase(
    $id: ID!
    $products: [ProductInput]!
    $supplier_name: String!
    $supplier_id: String!
    $total_item: String
    $total_quantity: String
    $total_amount: String
    $cgst: String
    $sgst: String
    $discount: String
    $igst: String
    $tax: String
    $net_amount: String!
  ) {
    editPurchase(
      id: $id
      products: $products
      supplier_name: $supplier_name
      supplier_id: $supplier_id
      total_item: $total_item
      total_quantity: $total_quantity
      total_amount: $total_amount
      cgst: $cgst
      sgst: $sgst
      igst: $igst
      discount: $discount
      tax: $tax
      net_amount: $net_amount
    ) {
      message
    }
  }
`;
