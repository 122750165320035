import React from "react";
import { Snackbar as MuiSnackbar, SnackbarProps } from "@mui/material";
import SnackbarContent from "@mui/material/SnackbarContent";
import PheonixSnackbarProps from "../interfaces/PheonixSnackbarProps";

const PheonixSnackbar: React.FC<PheonixSnackbarProps> = ({
  contentProps,
  vertical = "top",
  horizontal = "center",
  ...props
}) => {
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical, horizontal }}
      {...props}
      sx={{
        "& .css-1eg4x30-MuiPaper-root-MuiSnackbarContent-root": {
          minWidth: "100px",
        },
        "& .css-1or2qb2-MuiPaper-root-MuiSnackbarContent-root": {
          minWidth: "100px",
        },
        "& .css-1lv8x4v": {
          minWidth: "100px",
        },
      }}
    >
      <SnackbarContent {...contentProps} message={props.message} />
    </MuiSnackbar>
  );
};
export default PheonixSnackbar;
