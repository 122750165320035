import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SessionContextProviderProps } from "../interfaces/ContextInterface";
import SessionContextType from "../interfaces/SessioncontextProps";
import { ERROR_MESSAGES } from "../constants";
const SessionContext = createContext<SessionContextType | undefined>(undefined);
const SessionContextProvider: React.FC<SessionContextProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn") === "true";
    setIsLoggedIn(loggedIn);
  }, []);
  const logins = () => {
    localStorage.setItem("isLoggedIn", "true");
    setIsLoggedIn(true);
  };
  const logout = () => {
    localStorage.setItem("isLoggedIn", "false");
    navigate("/", { replace: true });
    setIsLoggedIn(false);
    window.history.replaceState(null, "", "/");
  };
  return (
    <SessionContext.Provider value={{ isLoggedIn, logins, logout }}>
      {children}
    </SessionContext.Provider>
  );
};
const useSession = (): SessionContextType => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error(ERROR_MESSAGES.SESSION_ERR_TEXT);
  }
  return context;
};
export { SessionContextProvider, useSession };
