import React, { useState } from "react";
import MuiButton from "@mui/material/Button";
import { CustomButtonProps } from "../interfaces/PheonixButtonProps";
import { useTheme } from "@mui/material/styles";
import theme from "../theme";

const PheonixButton: React.FC<CustomButtonProps> = ({
  variant = "contained",
  color = "primary",
  disabled = false,
  size = "small",
  fullWidth = false,
  onClick,
  style,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentTheme = useTheme();
  const handleClick = () => {
    onClick?.();
  };
  return (
    <MuiButton
      variant={variant}
      color={color}
      disabled={disabled || isLoading}
      size={size}
      fullWidth={fullWidth}
      onClick={handleClick}
      style={{
        ...style,
      }}
    >
      {children}
    </MuiButton>
  );
};
export default PheonixButton;
