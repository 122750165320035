import React from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import PheonixTableProps from "../interfaces/PheonixTableProps";
import PheonixPagination from "./PheonixPagination";
import PheonixFooter from "./PheonixFooter";
import { Tables } from "../constants";
import "../style.css";
const PheonixTable: React.FC<PheonixTableProps> = ({
  columns,
  data,
  totalPages,
  currentPage,
  handlePageChange,
  rowsPerPage,
  onRowsPerPageChange,
}) => {
  if (!data || !Array.isArray(data)) {
    return <div>{Tables}</div>;
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const tableHeight = data.length > 5 ? "350px" : "hidden";
  return (
    <>
      <Paper
        sx={{
          width: isMobile ? "116%" : "104%",
          borderTopRightRadius: 0,
          marginLeft: isMobile ? "-17px" : "-20px",
          borderTopLeftRadius: 0,
          borderBottomRightRadius: 20,
          borderBottomLeftRadius: 20,
          boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.25);",
        }}
      >
        <TableContainer
          sx={{
            maxHeight: tableHeight,
            overflowY: data.length > 5 ? "auto" : "hidden",
          }}
        >
          <Table
            style={{ overflowX: "auto" }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column: any) => (
                  <TableCell
                    key={column.id}
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.17px",
                      
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any) => (
                <TableRow key={row.id}>
                  {columns.map((column: any) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        padding: "8px 4px 8px 15px",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#000000",
                        
                      }}
                    >
                      {column.id === "actions"
                        ? column.render(row)
                        : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <PheonixPagination
          count={totalPages}
          currentPage={currentPage}
          onChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          sx={{ paddingBottom: "40px" }}
        />
      </Paper>
    </>
  );
};
export default PheonixTable;
