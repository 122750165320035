import * as React from "react";
import TablePagination from "@mui/material/TablePagination";
import PheonixPaginationProps from "../interfaces/PheonixPaginationProps";
const PheonixPagination: React.FC<PheonixPaginationProps> = ({
  count,
  currentPage,
  onChange,
  rowsPerPage,
  onRowsPerPageChange,
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    const nextPage = Math.max(
      0,
      Math.min(newPage, Math.ceil(count / rowsPerPage) - 1)
    );
    const syntheticEvent = event ? event : { target: null };
    onChange(syntheticEvent as React.ChangeEvent<unknown>, nextPage + 1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onRowsPerPageChange(event);
  };
  return (
    <TablePagination
      component="div"
      count={count}
      page={currentPage - 1}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[5, 10, 20, 30, 50]}
    />
  );
};
export default PheonixPagination;
