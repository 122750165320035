import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Purchase from "../../assets/Purchase.png";
import Group from "../../assets/Group.png";
import People from "../../assets/people.png";
import Vector from "../../assets/Vector.png";
import Sale from "../../assets/Sale.png";
import ProductList from "./ProductList";
import axios from "axios";
import TopSelling from "./TopSelling";
import SalesByProduct from "./SalesProduct";
import StatCard from "./StatCard";
import { api } from "../../api";
import SalesPurchase from "./SalesPurchase";
import InvoiceProps from "../../interfaces/InvoiceProps";
import { GET_SALES } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
interface ApiResponse {
  contactsCount: number;
  groupsCount: number;
  messagesSent: number;
  messages: any[];
  smsCounts: {
    sent: number;
    failed: number;
  };
}
const Home: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleViewAllClick = () => {
    setShowAll(!showAll);
  };

  const [filteredData, setFilteredData] = useState<InvoiceProps[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [tableData, setTableData] = useState<InvoiceProps[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [customerFormData, setCustomerFormData] = useState({
    "Invoice No": "",
    Customer: "",
    InvoiceDate: "",
  });
  const limit = rowsPerPage;
  const { loading, error, data, refetch } = useQuery(GET_SALES, {
    variables: {
      limit,
      offset: (currentPage - 1) * limit,
    },
    onCompleted: (data) => {
      setTableData(data.getAllSales.sales);
      setTableCount(data.getAllSales.totalCount);
      setTotalPages(Math.ceil(data.getAllSales.totalCount / limit));
    },
  });
  useEffect(() => {
    if (!loading && !error && data) {
      setTableData(data.getAllSales.sales);
      setTableCount(data.getAllSales.totalCount);
      setTotalPages(Math.ceil(data.getAllSales.totalCount / limit));
    }
  }, [data, loading, error]);
  const [dashboardCounts, setDashboardCounts] = useState<any>(null);
  const [graphdata, setgraphdata] = useState<any>(null);
  const [salescount, setsalescount] = useState<any>(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(api.baseUrl, {
        query: `
          query GetDashboardCounts {
            getDashboardCounts {
              customerCount
              supplierCount
              productCount
              salesCount
              purchaseCount
              recentsalesCount
              aggregatedStocks {
                  productName
                  totalQuantity
              }
              topSellingProducts {
                  productName
                  totalQuantity
              }
             }
          }
        `,
      });
      setDashboardCounts(response.data.data.getDashboardCounts);
      setsalescount(response.data.data.getDashboardCounts.salesCount);
      setgraphdata(response.data.data.getDashboardCounts.recentsalesCount);
      const topSellingData =
        response.data.data.getDashboardCounts.topSellingProducts;
      const items = topSellingData.map((item: any) => item.productName);
      const colors = [
        "#028652",
        "#0BA166",
        "#0BA166",
        "#0BA166",
        "#04DF89",
        "#04DF89",
        "#04DF89",
        "#04DF89",
        "#04DF89",
        "#04DF89",
      ];
      setTopSellingItems(items);
      setTopSellingColors(colors);
      const aggregatedStocks =
        response.data.data.getDashboardCounts.aggregatedStocks;
      const productListData: ProductItem[] = aggregatedStocks.map(
        (item: AggregatedStockItem) => ({
          name: item.productName,
          quantity: item.totalQuantity,
        })
      );
      setProductStockItems(productListData);
    } catch (error) {
      console.error(error);
    }
  };
  const [productStockItems, setProductStockItems] = useState<ProductItem[]>([]);
  const [topSellingItems, setTopSellingItems] = useState<string[]>([]);
  const [topSellingColors, setTopSellingColors] = useState<string[]>([]);
  interface ProductItem {
    name: string;
    quantity: number;
  }
  interface AggregatedStockItem {
    productName: string;
    totalQuantity: number;
  }
  const column = [
    { id: "sno", label: "S.No" },
    { id: "date", label: "Date" },
    { id: "message", label: "Message" },
  ];

  return (
    <Box
      style={{
        display: "flex",
        backgroundColor: "#F1F1F1",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "1.5%",
          justifyContent: "space-around",
          marginRight: "35px",
        }}
      >
        <StatCard
          data={[
            {
              count: dashboardCounts?.customerCount,
              name: "Customer",
              image: People,
              backgroundColor: "#D5F3E7",
            },
            {
              count: dashboardCounts?.supplierCount,
              name: "Supplier",
              image: Group,
              backgroundColor: "#E1E1E1",
            },
            {
              count: dashboardCounts?.productCount,
              name: "Products",
              image: Vector,
              backgroundColor: "#F2DADA",
            },
            {
              count: dashboardCounts?.salesCount,
              name: "Sales",
              image: Sale,
              backgroundColor: "#D1EAFB",
            },
            {
              count: dashboardCounts?.purchaseCount,
              name: "Purchase",
              image: Purchase,
              backgroundColor: "#F8F6DE",
            },
          ]}
        />
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "auto",
          marginTop: "0px",
          marginLeft: "0%",
          marginRight: "0%",
          width: "100%"
        }}
      >
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top" }}>
                <ProductList items={productStockItems} />
              </td>
              <td style={{ verticalAlign: "top" }}>
                <TopSelling items={topSellingItems} backgroundColors={topSellingColors} />
              </td>
              <td style={{ verticalAlign: "top" }} rowSpan={2}>
                <SalesPurchase graphdata={graphdata} count={salescount} />
                <SalesByProduct />
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>

  );
};

export default Home;
