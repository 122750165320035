import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import ProductsModalProps from "../interfaces/ProductsModalProps";
import PheonixButton from "./PheonixButton";
import CloseIcon from "@mui/icons-material/Close";
import { ERROR_MESSAGES } from "../constants";

const PheonixProductModal = <T extends Record<string, any>>({
  open,
  onClose,
  title,
  fields,
  onConfirm,
  close,
  confirmButtonText,
  formData,
  setFormData,
  isEditModal,
  fieldErrors1,
  setFieldErrors1,
}: ProductsModalProps<T>) => {
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  useEffect(() => {
    if (open) {
      setFieldErrors({});
      setIsButtonDisabled(!isEditModal);
      setFieldErrors1?.({});
    }
  }, [open, isEditModal]);
  useEffect(() => {
    validateForm();
  }, [formData]);

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    setIsConfirmDisabled(hasErrors);
  }, [fieldErrors]);

  const validateForm = () => {
    let hasErrors = false;
    let isAnyFieldEmpty = false;
    fields.forEach((field) => {
      const fieldValue = formData[field.label].trim();
      if (field.label !== "Retail Margin in %" && !fieldValue) {
        isAnyFieldEmpty = true;
      } else {
        validateField(field.label);
      }
    });
    setIsButtonDisabled(isAnyFieldEmpty || hasErrors);
  };
  const handleFieldChange = (fieldLabel: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [fieldLabel]: value }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
    validateForm();
  };
  const handleFieldBlur = (fieldLabel: string) => {
    validateField(fieldLabel);
  };

  const clearErrorMessage = (fieldLabel: string) => {
    setFieldErrors1?.((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
  };
  const validateField = (fieldLabel: string) => {
    const fieldValue = (formData as { [key: string]: string })[fieldLabel];
    if (!fieldValue) {
      if (fieldLabel === "Product Name and Packing") {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldLabel]: ERROR_MESSAGES.PROD_REQ,
        }));
      } else if (fieldLabel === "GST in %") {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldLabel]: ERROR_MESSAGES.HSN_REQ,
        }));
      }
    } else if (fieldValue && fieldLabel === "GST in %") {
      let value = !/^\d+$/.test(fieldValue);
      if (value) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldLabel]: ERROR_MESSAGES.GST_VAL,
        }));
      } else {
        setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
      }
    } else if (fieldValue && fieldLabel === "Retail Margin in %") {
      let value = !/^\d+(\.\d+)?$/.test(fieldValue);
      if (value) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldLabel]: ERROR_MESSAGES.PTR_VAL,
        }));
      } else {
        setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
      }
    } else {
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
    }
  };
  const handleConfirm = () => {
    validateForm();
    if (!isConfirmDisabled) {
      onConfirm();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialogTitle-root": {
          color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
          fontFamily: "Inter",
          display: "flex",
        },
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          backgroundColor: "#fff !important",
          color: "rgba(0, 0, 0, 0.87) !important",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
          position: "relative !important",
          overflowY: "auto !important",
          display: "flex !important",
          flexDirection: "column !important",
          maxHeight: "341px !important",
          maxWidth: "707px",
          padding: "4px 42px",
        },
        "& .css-uhb5lp": {
          backgroundColor: "#fff !important",
          color: "rgba(0, 0, 0, 0.87) !important",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
          position: "relative !important",
          overflowY: "auto !important",
          display: "flex !important",
          flexDirection: "column !important",
          maxHeight: "341px !important",
          maxWidth: "707px",
          padding: "4px 42px",
        },
      }}
    >
      <DialogTitle
        sx={{
          marginTop: "20px",
          fontFamily: "Inter",
          fontSize: "24px",
          fontWeight: 700,
          color: "#1C1B1F",
          lineHeight: "normal",
          fontStyle: "normal",
        }}
      >
        {" "}
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: "67px",
            top: "40px",
            width: " 24px",
            height: " 24px",
            padding: "5px",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid
                item
                xs={index === 0 || index === 5 ? 12 : 6}
                key={field.label}
              >
                <TextField
                  fullWidth
                  label={field.label}
                  variant="outlined"
                  multiline={field.label === "Address"}
                  value={(formData as { [key: string]: string })[field.label]}
                  error={
                    !!fieldErrors1?.[field.label] ||
                    !!fieldErrors?.[field.label]
                  }
                  helperText={
                    fieldErrors1?.[field.label] || fieldErrors[field.label]
                  }
                  onBlur={() => handleFieldBlur(field.label)}
                  onFocus={() => clearErrorMessage(field.label)}
                  onChange={(e) =>
                    handleFieldChange(field.label, e.target.value)
                  }
                  style={{
                    marginTop: "10px",
                    borderRadius: "4px",
                    fontFamily: "Inter",
                    borderColor: "#01579B",
                  }}
                  sx={{
                    width: "100%",
                    marginTop: "10px",
                    "& label.Mui-focused": {
                      color: "#1A504C",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "green",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#1A504C!important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PheonixButton
          onClick={onClose}
          style={{
            backgroundColor: "#165D59",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            fontFamily: "Inter",
            marginBottom: "15px",
            marginRight: "15px",
            padding: "6px 16px",
            width: "76px",
            height: "36px",
          }}
        >
          {close}
        </PheonixButton>
        <PheonixButton
          onClick={handleConfirm}
          disabled={isButtonDisabled || isConfirmDisabled}
          style={{
            backgroundColor:
              isButtonDisabled || isConfirmDisabled ? "lightgray" : "#165D59",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            fontFamily: "Inter",
            marginBottom: "15px",
            marginRight: "15px",
            padding: "6px 16px",
            width: "76px",
            height: "36px",
          }}
        >
          {confirmButtonText}
        </PheonixButton>
      </DialogActions>
    </Dialog>
  );
};
export default PheonixProductModal;
