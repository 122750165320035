import React, { ChangeEvent, useEffect, useState } from "react";
import PheonixPurchaseTableProps from "../interfaces/PheonixPurchaseTableProps";
import { useQuery, useMutation } from "@apollo/client";
import { GET_STOCKS, GET_PRODUCTS, GET_ALL_PRODUCTS } from "../graphql/queries";
import PheonixSnackbar from "./PheonixSnackbar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Typography,
  InputAdornment,
  Button,
} from "@mui/material";
import { ERROR_MESSAGES, INVOICE_NUMBER } from "../constants";
import Paper from "@mui/material/Paper";
import StockProps from "../interfaces/StockProps";
import ProductProps from "../interfaces/ProductProps";
import { RowData } from "../interfaces/PheonixInvoiceTableProps";
import { getValue } from "@mui/system";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PheonixProductModal from "./PheonixProductModal";
import { ADD_PRODUCT } from "../graphql/mutations";
interface Stock {
  id: string;
  product_name_packing: string;
  gst: string;
  retail_margin: string;
}

const PheonixPurchaseTable: React.FC<PheonixPurchaseTableProps> = ({
  columns,
  rows,
  handleInputChange,
  handleInvoiceTableChange,
  quantityErrors,
  handleNetAmountChange,
  totalEditAmount,
  totalCgst,
  totalNet,
  totalEditTax,
  handleAddRow,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [productNames, setProductNames] = useState<
    { id: string; name: string; gst: string }[]
  >([]);
  const [stocksData, setStocksData] = useState<StockProps[]>([]);
  const [selectedStocksData, setSelectedStocksData] = useState<StockProps[]>(
    []
  );
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [textFieldValues, setTextFieldValues] = useState<
    Record<string, string>
  >({});
  const [mappedData, setMappedData] = useState<RowData | null>(null);
  const [isSGSTChecked, setIsSGSTChecked] = useState(true);
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [isCGSTChecked, setIsCGSTChecked] = useState(true);
  const [isIGSTChecked, setIsIGSTChecked] = useState(false);
  const [netAmount, setNetAmount] = useState<number | null>(null);
  const [cgst, setCgst] = useState<number | 0>(0);
  const [sgst, setSgst] = useState<number | null>(null);
  const [igst, setIgst] = useState<number | null>(null);
  const [tax, setTax] = useState<number | null>(null);
  const [discount, setDiscount] = useState<number | null>(null);
  const [productFormData, setProductFormData] = useState({
    "Product Name and Packing": "",
    "GST in %": "",
    "Retail Margin in %": "",
  });
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [iscustomerButtonDisabled, setIscustomerButtonDisabled] =
    useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [nameerrorMessage, setNameErrorMessage] = useState("");
  const [addresserrorMessage, setAddressErrorMessage] = useState("");
  const [phonnoerrorMessage, setPhonenoErrorMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [addProductMutation] = useMutation(ADD_PRODUCT);
  const limit = rowsPerPage;
  const { loading, error, data, refetch } = useQuery(GET_ALL_PRODUCTS, {
    onCompleted: (data) => {
      setProductNames(
        data.getProductList.map((stock: Stock) => ({
          id: stock.id,
          name: stock.product_name_packing,
          gst: stock.gst,
          retail_margin: stock.retail_margin,
        }))
      );
    },
  });
  useEffect(() => {
    if (!loading && !error && data) {
      setProductNames(
        data.getProductList.map((stock: Stock) => ({
          id: stock.id,
          name: stock.product_name_packing,
          gst: stock.gst,
          retail_margin: stock.retail_margin,
        }))
      );
    }
  }, [data, loading, error, refetch]);

  useEffect(() => {
    refetch();
  }, []);

  const totalQuantity = rows.reduce((sum, row) => {
    const quantity = parseInt(row["Quantity"]) || 0;
    return sum + quantity;
  }, 0);

  const totalTax =
    parseFloat(
      rows
        .filter(
          (row) =>
            row["Quantity"] &&
            row["PTR"] &&
            row["GST"] &&
            parseFloat(row["Quantity"]) > 0 &&
            parseFloat(row["PTR"]) > 0
        )
        .reduce((sum, row) => {
          const quantity = parseFloat(row["Quantity"]) || 0;
          const ptr = parseFloat(row["PTR"]) || 0;
          const gst = parseFloat(row["GST"]) || 0;
          const amount = quantity * ptr;
          const gstAmount = (gst / 100) * amount;
          return sum + gstAmount;
        }, 0)
        .toFixed(2)
    ) || null;

  const handleCloseModal = () => {
    setProductFormData({
      "Product Name and Packing": "",
      "GST in %": "",
      "Retail Margin in %": "",
    });
    setErrorMessage1("");
    setAddressErrorMessage("");
    setNameErrorMessage("");
    setIsModalOpen(false);
  };
  const fields1 = [
    { label: "Product Name and Packing", type: "text" },
    { label: "GST in %", type: "text" },
    { label: "Retail Margin in %", type: "text" },
  ];

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  const totalAmount =
    parseFloat(
      rows
        .filter(
          (row) =>
            row["Quantity"] &&
            row["PTR"] &&
            parseFloat(row["Quantity"]) > 0 &&
            parseFloat(row["PTR"]) > 0
        )
        .reduce((sum, row) => {
          const quantity = parseFloat(row["Quantity"]) || 0;
          const ptr = parseFloat(row["PTR"]) || 0;
          const amount = quantity * ptr;
          return sum + amount;
        }, 0)
        .toFixed(2)
    ) || null;

  const newNetAmount =
    totalAmount !== null && totalTax !== null
      ? discount
        ? totalAmount + totalTax - (totalAmount + totalTax) * (discount / 100)
        : totalAmount + totalTax
      : null;

  useEffect(() => {
    if (handleNetAmountChange) {
      handleNetAmountChange(
        netAmount,
        cgst,
        rows.length,
        totalQuantity,
        totalAmount,
        tax,
        igst,
        discount
      );
    }
  }, [netAmount, handleNetAmountChange]);

  useEffect(() => {
    if (totalNet) {
      setNetAmount(totalNet);
    }
  }, [totalEditAmount]);

  const handleCGSTChange = () => {
    if (!isCGSTChecked && !isIGSTChecked) {
      setIsCGSTChecked(true);
    } else if (isIGSTChecked) {
      setIsIGSTChecked(false);
      setIsCGSTChecked(true);
    }
  };

  const handleIGSTChange = () => {
    if (!isCGSTChecked && !isIGSTChecked) {
      setIsIGSTChecked(true);
    } else if (isCGSTChecked) {
      setIsCGSTChecked(false);
      setIsIGSTChecked(true);
    }
  };

  const totalIGST =
    parseFloat(
      rows
        .filter(
          (row) =>
            row["Quantity"] &&
            row["PTR"] &&
            row["GST"] &&
            parseFloat(row["Quantity"]) > 0 &&
            parseFloat(row["PTR"]) > 0
        )
        .reduce((sum, row) => {
          const quantity = parseFloat(row["Quantity"]) || 0;
          const ptr = parseFloat(row["PTR"]) || 0;
          const gst = parseFloat(row["GST"]) || 0;
          const amount = quantity * ptr;
          const gstAmount = (gst / 100) * amount;

          return sum + (isIGSTChecked ? gstAmount : 0);
        }, 0)
        .toFixed(2)
    ) || null;

  const mapStockToRowData = (stock: RowData, rowIndex: number): RowData => {
    const { id, name, gst, retail_margin } = stock;

    return {
      product_id: id,
      ProductName: name,
      GST: gst,
      PTR: retail_margin,
    };
  };

  const handleProductChange = (
    event: React.ChangeEvent<{}>,
    newValue: any,
    rowIndex: number
  ) => {
    setSelectedProduct(newValue);
    if (newValue) {
      const selectedStock: RowData | undefined = productNames.find(
        (stock) => stock.id === newValue.id
      );
      if (selectedStock) {
        const mappedRowData: RowData = mapStockToRowData(
          selectedStock,
          rowIndex
        );
        handleInvoiceTableChange(mappedRowData, rowIndex);
      }
    }
  };

  const handleConfirm = async () => {
    try {
      const result = await addProductMutation({
        variables: {
          product_name_packing: productFormData["Product Name and Packing"],
          gst: productFormData["GST in %"],
          retail_margin: productFormData["Retail Margin in %"],
        },
      });
      const successMessage = result.data.createProducts.message;

      if (successMessage) {
        handleCloseModal();
        setSnackbarOpen(true);
        setSnackbarMessage(successMessage);
        refetch();
      }
    } catch (error: any) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        ["Product Name and Packing"]: error.message,
      }));
    }
  };

  const updateFormEmptyState = () => {
    const isEmpty = Object.values(productFormData).some(
      (value) => value.trim() === ""
    );
    setIsFormEmpty(isEmpty);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleNetAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredNetAmount = parseFloat(event.target.value);
    if (!isNaN(enteredNetAmount)) {
      setNetAmount(enteredNetAmount);
    } else {
      setNetAmount(0);
    }
  };

  return (
    <TableContainer style={{ marginTop: "24px", width: "960px" }}>
      <Table>
        <div>
          <TableRow style={{ border: "1px solid lightgrey" }}>
            {columns.map((column) => (
              <TableCell
                key={column.field}
                style={{
                  width: column.width,
                  padding: column.padding || "0px",
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "inter",
                  color: "black",
                  textAlign: "center",
                }}
              >
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </div>

        <div>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                style={{ border: "1px solid lightgrey", fontFamily: "inter" }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    style={{
                      width: column.width,
                      fontFamily: "inter",
                      border: "1px solid lightgrey",
                    }}
                  >
                    {column.render ? (
                      column.render(index)
                    ) : column.field === "ProductName" ? (
                      <div>
                        <Autocomplete
                          id="combo-box-demo"
                          options={productNames}
                          disableClearable={true}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) =>
                            handleProductChange(event, newValue, index)
                          }
                          value={
                            productNames.find(
                              (option) => option.name === row.ProductName
                            ) || { id: "", name: "", gst: "" }
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Product" />
                          )}
                          sx={{
                            "& label.Mui-focused": {
                              color: "#1A504C",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "green",
                            },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#1A504C!important",
                              },
                            },
                          }}
                        />
                      </div>
                    ) : column.field === "GST" || column.field === "PTR" ? (
                      <div>{row[column.field]}</div>
                    ) : (
                      <div>
                        <TextField
                          type="text"
                          value={row[column.field] || ""}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(
                              index,
                              column.field,
                              e.target.value,
                              row.availableQuantity
                            )
                          }
                          style={{
                            width: "100%",
                            padding: "4px",
                            height: "10px",
                            marginBottom: "40px",
                          }}
                          sx={{
                            "& label.Mui-focused": {
                              color: "#1A504C",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "green",
                            },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#1A504C!important",
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              onClick={handleAddRow}
              style={{
                backgroundColor: "#165D59",
                color: "white",
                fontSize: "14px",
                fontFamily: "Inter",
                margin: "15px",
                marginLeft: "2px",
              }}
            >
              {ERROR_MESSAGES.ADD_ITEM}
            </Button>
            <Button
              onClick={handleOnClick}
              style={{
                backgroundColor: "#165D59",
                color: "white",
                fontSize: "14px",
                fontFamily: "Inter",
                margin: "15px",
              }}
            >
              {ERROR_MESSAGES.ADD_NEW_PRODUCT}
            </Button>{" "}
          </div>
        </div>

        <TableContainer></TableContainer>
      </Table>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          marginTop: "50px",
          marginBottom: "10px",
          marginLeft: "5px",
        }}
      >
        <Paper elevation={3} sx={{ width: "40%", height: "104px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "25px",
            }}
          >
            <Table
              aria-label="spanning table"
              style={{ border: "red", marginTop: "0px" }}
            >
              <TableBody style={{ border: "none" }}>
                <TableRow style={{ left: "1%" }}>
                  <TableCell
                    colSpan={2}
                    style={{
                      border: "none",
                      width: "120px",
                      borderBottomLeftRadius: 0,
                      color: "black",
                      fontWeight: 600,
                      font: "Inter",
                    }}
                  >
                    {ERROR_MESSAGES.NET_PAID}:
                  </TableCell>
                  <TableCell
                    colSpan={0}
                    style={{
                      border: "none",
                      padding: "4px",
                      marginLeft: "5px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Net Amount"
                      type="text"
                      inputProps={{ style: { height: "15px", border: "none" } }}
                      onChange={handleNetAmount}
                      value={netAmount}
                      style={{
                        height: "5px",
                        width: "80%",
                        marginBottom: "45px",
                      }}
                      sx={{
                        "& label.Mui-focused": {
                          color: "#1A504C",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "green",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#1A504C!important",
                          },
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
      <PheonixProductModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={ERROR_MESSAGES.BUTTON_TITLE_Product}
        fields={fields1}
        onConfirm={handleConfirm}
        confirmButtonText={ERROR_MESSAGES.SAVE}
        formData={productFormData}
        setFormData={setProductFormData}
        close={ERROR_MESSAGES.CLOSE}
        isEditModal={false}
        errorMessage={errorMessage1}
        phonenoErrorMessage={phonnoerrorMessage}
        setErrorMessage={setErrorMessage1}
        setPhonenoErrorMessage={setPhonenoErrorMessage}
        nameerrorMessage={nameerrorMessage}
        setNameErrorMessage={setNameErrorMessage}
        addresserrorMessage={addresserrorMessage}
        setAddressErrorMessage={setAddressErrorMessage}
        disabled={!iscustomerButtonDisabled}
        isFormEmpty={isFormEmpty}
        updateFormEmptyState={updateFormEmptyState}
        fieldErrors1={fieldErrors}
        setFieldErrors1={setFieldErrors}
      />
      <PheonixSnackbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "40px",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        vertical="top"
        horizontal="center"
        contentProps={{ sx: { backgroundColor: "#1A504C" } }}
      />
    </TableContainer>
  );
};

export default PheonixPurchaseTable;
