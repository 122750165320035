import React, { ChangeEvent } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import PheonixLoginTextFieldProps from "../interfaces/PheonixLoginTextFieldProps";
const PhoenixLoginTextField: React.FC<PheonixLoginTextFieldProps> = ({
  id,
  type,
  value,
  placeholder,
  label,
  variant,
  onChange,
  onFocus,
  onBlur,
  endAdornment,
  style,
  required,
  rememberMe,
  className,
  error,
  helperText,
  sx,
}) => {
  return (
    <TextField
      id={id}
      type={type}
      value={value}
      placeholder={placeholder}
      label={label}
      variant={variant}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: endAdornment,
        className: className,
        style: style,
      }}
      required={required}
      sx={{
        "& label.Mui-focused": {
          color: "#1A504C",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "green",
        },
        "& .MuiInputLabel-root": {
          color: "#1A504C",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#1A504C!important",
          },
        },
      }}
    />
  );
};
export default PhoenixLoginTextField;
