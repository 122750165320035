import React, { useState, useEffect, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { GET_CUSTOMERS } from "../graphql/queries";
import { ERROR_MESSAGES } from "../constants";
import InvoiceSalesModal from "../components/InvoiceSalesModal";
import PheonixModal from "../components/PheonixModal";
import { useTheme } from "@mui/material/styles";
import {
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  EDIT_CUSTOMER,
  ADD_SALES_INVOICE,
  ADD_PURCHASE_INVOICE,
} from "../graphql/mutations";
import PheonixSnackbar from "../components/PheonixSnackbar";
import { useQuery, useMutation } from "@apollo/client";
import PheonixSnackbarProps from "../interfaces/PheonixSnackbarProps";
import CustomerProps from "../interfaces/CustomerProps";
import PheonixAppbar from "../components/PheonixAppbar";
import PheonixPaper from "../components/PheonixPaper";
import styled from "styled-components";
import PheonixSearchComponent from "../components/PheonixSearchComponent";
import PheonixButton from "../components/PheonixButton";
import InvoiceSales from "./InvoiceSales";
import InvoicePurchase from "./InvoicePurchase";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { RowData, TableColumn } from "../interfaces/PheonixInvoiceTableProps";
const StyledDiv = styled.div`
  padding: 4px 42px 4px 50px;
  overflowx: hidden;
  @media (max-width: 707px) {
    padding: 4px 42px 4px 20px;
  }
`;
export default function Invoice() {
  const theme = useTheme();
  const tabColor = theme.palette.primary.main;
  const [value, setValue] = React.useState("1");
  const [rows, setRows] = useState<RowData[]>([
    {
      sNo: 1,
      customerName: "",
      invoiceNo: "",
      MRP: "",
      ExpireDate: "",
      Quantity: "",
      GST: "",
      Amount: "",
    },
  ]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [tableData, setTableData] = useState<CustomerProps[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSalesModalOpen, setIsSalesModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [invoiceSalesStatus, setInvoiceSalesStatus] = useState(false);
  const [invoicePurchaseStatus, setInvoicePurchaseStatus] = useState(false);
  const [filteredCustomersData, setFilteredCustomersData] = useState<
    CustomerProps[]
  >([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [nameerrorMessage, setNameErrorMessage] = useState("");
  const [addresserrorMessage, setAddressErrorMessage] = useState("");
  const [phonnoerrorMessage, setPhonenoErrorMessage] = useState("");
  const [email, setemail] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [customerIdToDelete, setCustomerIdToDelete] = useState<string>("");
  const [iscustomerButtonDisabled, setIscustomerButtonDisabled] =
    useState(true);
  const [addCustomerMutation] = useMutation(ADD_CUSTOMER);
  const [deleteCustomerMutation] = useMutation(DELETE_CUSTOMER);
  const [addSalesInvoice] = useMutation(ADD_SALES_INVOICE);
  const [addPurchaseInvoice] = useMutation(ADD_PURCHASE_INVOICE);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");

  const limit = rowsPerPage;

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const updateFormEmptyState = () => {};
  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };
  const handleOpenModal = () => {
    if (value === "1") {
      setIsSalesModalOpen(true);
      setIsEditModal(false);
    } else if (value === "2") {
      setIsEditModal(false);
      setIsSalesModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsSalesModalOpen(false);
  };
  const fields = [
    { label: "Customer Name", type: "text" },
    { label: "Phone Number", type: "number" },
    { label: "Email", type: "text" },
    { label: "Address", type: "text" },
  ];

  const handleEmailFocus = () => {
    setErrorMessage("");
  };
  const handleEmailBlur = () => {
    const emailRegex = ERROR_MESSAGES.EMAIL_REG;
    if (!emailRegex.test(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
    } else {
      setErrorMessage("");
    }
  };

  const [emailError, setEmailError] = useState("");

  const handleConfirm = async (
    customerName: { id: string; name: string }[],
    newNetAmount: number | null,
    cgst: number | null,
    tax: number | null,
    items: number | null,
    quantity: number | null,
    total: number | null,
    rows: RowData,
    igst: number | null,
    discount: number | null
  ) => {
    const products = rows.map((row: RowData) => ({
      product_name: row.ProductName || null,
      product_id: row.product_id || null,
      batch_no: row.BatchNumber || null,
      mrp: row.MRP || null,
      ptr: row.PTR || null,
      expiry_date: row.ExpiryDate || null,
      quantity: row.Quantity || null,
      gst: row.GST || null,
      amount: row.Amount || null,
    }));
    const name =
      customerName && typeof customerName === "object" && "name" in customerName
        ? customerName.name
        : "";
    const id =
      customerName && typeof customerName === "object" && "id" in customerName
        ? customerName.id
        : "";
    if (value === "1") {
      let result;
      result = await addSalesInvoice({
        variables: {
          customer_name: name,
          customer_id: id,
          products: products,
          total_item: items?.toString(),
          total_quantity: quantity?.toString(),
          total_amount: total?.toString(),
          net_amount: newNetAmount?.toString(),
          tax: tax?.toString(),
          discount: discount?.toString(),
          cgst: cgst?.toString(),
          sgst: cgst?.toString(),
          igst: igst?.toString(),
        },
      });
      if (result.data && result.data.createSales) {
        const { message } = result.data.createSales;
        const { invoice_no } = result.data.createSales.sales;
        if (invoice_no) {
          setInvoiceSalesStatus(true);
          setSnackbarOpen(true);
          setIsSalesModalOpen(false);
          setSnackbarMessage(
            `${ERROR_MESSAGES.SALES_INVOICE_SUCCESS} ${invoice_no}`
          );
        } else {
          setSnackbarOpen(true);
          setIsSalesModalOpen(false);
          setSnackbarMessage(ERROR_MESSAGES.INVOICE_FAILURE);
        }
      } else {
        setSnackbarOpen(true);
        setIsSalesModalOpen(false);
        setSnackbarMessage(ERROR_MESSAGES.INVOICE_FAILURE);
      }
    } else if (value === "2") {
      let result;
      result = await addPurchaseInvoice({
        variables: {
          products: products,
          supplier_name: name,
          supplier_id: id,
          total_item: items?.toString(),
          total_quantity: quantity?.toString(),
          total_amount: total?.toString(),
          cgst: tax?.toString(),
          sgst: tax?.toString(),
          igst: cgst?.toString(),
          discount: discount?.toString(),
          tax: cgst?.toString(),
          net_amount: newNetAmount?.toString(),
        },
      });

      if (result.data && result.data.createPurchase) {
        const { message } = result.data.createPurchase;
        const { invoice_no } = result.data.createPurchase.purchase;
        if (invoice_no) {
          setInvoicePurchaseStatus(true);
          setSnackbarOpen(true);
          setIsSalesModalOpen(false);
          setSnackbarMessage(
            `${ERROR_MESSAGES.PURCHASE_INVOICE_SUCCESS} ${invoice_no}`
          );
        } else {
          setSnackbarOpen(true);
          setIsSalesModalOpen(false);
          setSnackbarMessage(message);
        }
      } else {
        setSnackbarOpen(true);
        setIsSalesModalOpen(false);
        setSnackbarMessage(ERROR_MESSAGES.INVOICE_FAILURE);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div style={{ width: "95%" }}>
      <StyledDiv>
        <PheonixPaper
          elevation={1}
          marginLeft={-20}
          marginRight={-30}
          height={100}
          marginTop={35}
          style={{
            border: 0,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            marginBottom: "10px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px",
              margin: "15px",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "40%" }}
            >
              <TabContext value={value}>
                <TabList
                  onChange={handleChange}
                  sx={{
                    "& .css-1gf9ldk-MuiButtonBase-root-MuiTab-root.Mui-selected":
                      {
                        color: "#165D59",
                      },
                    "& .css-zj9cgi.Mui-selected": {
                      color: "#165D59",
                    },
                    "& .Mui-selected": {
                      color: tabColor,
                      fontSize: "20px",
                      backgroundColor: "#BDBDBD",
                      border: "1px solid #165D59",
                    },
                    "& .Mui-tab": {
                      color: "black",
                      border: "1px solid lightgrey",
                    },
                  }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#165D59",
                      height: 4,
                    },
                  }}
                >
                  <Tab
                    label="Sales"
                    value="1"
                    sx={{
                      width: "240px",
                      minHeight: 42,
                      color: "#000000",
                      fontSize: "18px",
                      fontWeight: 700,
                      border: "2px solid lightgrey",
                    }}
                  />
                  <Tab
                    label="Purchase"
                    value="2"
                    sx={{
                      width: "240px",
                      minHeight: 42,
                      color: "#000000",
                      fontSize: "18px",
                      fontWeight: 700,
                      border: "2px solid lightgrey",
                    }}
                  />
                </TabList>
              </TabContext>
            </div>
            <PheonixButton
              variant="contained"
              onClick={handleOpenModal}
              style={{
                color: "white",
                marginTop: "5px",
                backgroundColor: "#165D59",
                fontSize: "16px",
                padding: "6px 16px",
                borderRadius: "4px",
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                marginLeft: "100px",
              }}
            >
              {ERROR_MESSAGES.INVOICE}
            </PheonixButton>

            <div style={{ marginRight: "30px" }}>
              <PheonixSearchComponent onChange={handleSearch} />
            </div>
          </div>
        </PheonixPaper>

        <PheonixSnackbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          vertical="top"
          horizontal="center"
          contentProps={{ sx: { backgroundColor: "#1A504C" } }}
        />

        <InvoiceSalesModal
          open={isSalesModalOpen}
          onClose={handleCloseModal}
          title={
            value === "1"
              ? ERROR_MESSAGES.INVOICE_SALES
              : ERROR_MESSAGES.INVOICE_PURCHASE
          }
          fields={fields}
          onConfirm={handleConfirm}
          confirmButtonText={ERROR_MESSAGES.SAVE}
          formData={rows}
          setFormData={setRows}
          close={ERROR_MESSAGES.CLOSE}
          isEditModal={isEditModal}
          errorMessage={errorMessage}
          disabled={!iscustomerButtonDisabled}
          isFormEmpty={isFormEmpty}
          updateFormEmptyState={updateFormEmptyState}
          selectedTab={value}
        />
        <Box sx={{ typography: "body1", color: "#165D59" }}>
          <TabContext value={value}>
            <TabPanel value="1">
              <InvoiceSales
                status={invoiceSalesStatus}
                searchQuery={searchQuery}
              />
            </TabPanel>
            <TabPanel value="2">
              <InvoicePurchase
                status={invoicePurchaseStatus}
                searchQuery={searchQuery}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </StyledDiv>
    </div>
  );
}
