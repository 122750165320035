import { gql } from "@apollo/client";

export const GET_USER_QUERY = gql`
  query GetUser {
    user {
      id
      email
    }
  }
`;
export const GET_CUSTOMERS = gql`
  query GetAllCustomers($limit: Int!, $offset: Int!) {
    getAllCustomers(limit: $limit, offset: $offset) {
      customers {
        id
        serial_no
        customer_code
        customer_name
        phone_no
        email
        address
        user_type
        sales
      }
      totalCount
    }
  }
`;
export const GET_SUPPLIERS = gql`
  query GetAllSuppliers($limit: Int!, $offset: Int!) {
    getAllSuppliers(limit: $limit, offset: $offset) {
      suppliers {
        id
        serial_no
        supplier_code
        supplier_name
        registration_no
        phone_no
        email
        address
        user_type
        purchase
      }
      totalCount
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetAllProducts($limit: Int!, $offset: Int!) {
    getAllProducts(limit: $limit, offset: $offset) {
      totalCount
      products {
        id
        serial_no
        product_code
        product_name_packing
        gst
        retail_margin
        quantity
        totalQuantity
      }
    }
  }
`;
export const GET_STOCKS = gql`
  query GetAllStocks($limit: Int!, $offset: Int!) {
    getAllStocks(limit: $limit, offset: $offset) {
      totalCount
      stocks {
        id
        serial_no
        stock_code
        product_name
        batch_no
        mrp
        ptramt
        gst
        expiry_date
        quantity
      }
    }
  }
`;

export const GET_SALES = gql`
  query getAllSales($limit: Int!, $offset: Int!) {
    getAllSales(limit: $limit, offset: $offset) {
      sales {
        id
        serial_no
        sales_code
        invoice_no
        invoice_date
        customer_name
        customer_id
        customer_details {
          customer_code
          customer_name
          phone_no
          email
          address
        }
        total_item
        total_quantity
        total_amount
        cgst
        sgst
        igst
        discount
        tax
        net_amount
        products {
          product_name
          product_id
          batch_no
          mrp
          ptr
          expiry_date
          quantity
          gst
          amount
          stock
        }
      }
      totalCount
    }
  }
`;

export const GET_PURCHASE = gql`
  query GetAllPurchase($limit: Int!, $offset: Int!) {
    getAllPurchase(limit: $limit, offset: $offset) {
      purchase {
        id
        serial_no
        purchase_code
        invoice_no
        supplier_name
        invoice_date
        supplier_id
        supplier_details {
          supplier_code
          supplier_name
          phone_no
          email
          address
        }
        total_item
        total_quantity
        total_amount
        cgst
        sgst
        igst
        discount
        tax
        net_amount
        products {
          product_name
          product_id
          batch_no
          mrp
          ptr
          expiry_date
          quantity
          gst
          amount
        }
      }
      totalCount
    }
  }
`;

export const GET_ALL_CUSTOMERS = gql`
  query GetCustomerList {
    getCustomerList {
      id
      serial_no
      customer_code
      customer_name
      phone_no
      email
      address
      user_type
      sales
    }
  }
`;

export const GET_ALL_SUPPLIERS = gql`
  query GetSupplierList {
    getSupplierList {
      id
      serial_no
      supplier_code
      supplier_name
      registration_no
      phone_no
      email
      address
      user_type
      purchase
    }
  }
`;

export const GET_ALL_STOCKS = gql`
  query GetStockList {
    getStockList {
      id
      serial_no
      stock_code
      product_name
      batch_no
      mrp
      ptramt
      gst
      expiry_date
      quantity
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query GetProductList {
    getProductList {
      id
      serial_no
      product_code
      product_name_packing
      gst
      retail_margin
      quantity
      totalQuantity
    }
  }
`;

export const SEARCH_SUPPLIERS = gql`
  query SearchSuppliers($limit: Int, $offset: Int, $searchQuery: String!) {
    searchSuppliers(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      totalCount
      suppliers {
        id
        serial_no
        supplier_code
        supplier_name
        registration_no
        phone_no
        email
        address
        user_type
        purchase
      }
    }
  }
`;

export const SEARCH_CUSTOMERS = gql`
  query SearchCustomers($limit: Int, $offset: Int, $searchQuery: String!) {
    searchCustomers(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      totalCount
      customers {
        id
        serial_no
        customer_code
        customer_name
        phone_no
        email
        address
        user_type
        sales
      }
    }
  }
`;

export const SEARCH_PRODUCTS = gql`
  query SearchProducts($limit: Int, $offset: Int, $searchQuery: String!) {
    searchProducts(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      totalCount
      products {
        id
        serial_no
        product_code
        product_name_packing
        gst
        retail_margin
        quantity
        totalQuantity
      }
    }
  }
`;

export const SEARCH_STOCKS = gql`
  query SearchStocks($limit: Int, $offset: Int, $searchQuery: String!) {
    searchStocks(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      totalCount
      stocks {
        id
        serial_no
        stock_code
        product_name
        batch_no
        mrp
        ptramt
        gst
        expiry_date
        quantity
      }
    }
  }
`;
