import React, { useState, ReactNode } from "react";
import Sidebar from "./SideBar";
import PheonixAppBar from "../components/PheonixAppbar";
import { Box } from "@mui/system";
import LayoutProps from "../interfaces/LayoutProps";
import PheonixFooter from "../components/PheonixFooter";
const Layout: React.FC<LayoutProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      style={{
        flexGrow: 1,
        overflow: "hidden",
        marginTop: "-10px",
        marginRight: "-50px",
      }}
    >
      <PheonixAppBar title={title} isSidebarOpen={isOpen} />
      <div style={{ display: "flex" }}>
        <Sidebar isOpen={isOpen} onToggle={handleToggleSidebar} />
        <div
          style={{
            flexGrow: 1,
            backgroundColor: "#f1f1f1",
            overflow: "hidden",
          }}
        >
          {children}
        </div>
      </div>
      <PheonixFooter />
    </div>
  );
};

export default Layout;
