import React, { useState, useEffect, ChangeEvent } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_SUPPLIERS, SEARCH_SUPPLIERS } from "../graphql/queries";
import PheonixTable from "../components/PheonixTable";
import {
  CssBaseline,
  Box,
  Container,
  useTheme,
  Drawer,
  IconButton,
  CardContent,
  Card,
  Typography,
  Stack,
} from "@mui/material";
import PheonixSearchComponent from "../components/PheonixSearchComponent";
import PheonixButton from "../components/PheonixButton";
import styled from "styled-components";
import SupplierModal from "../components/SupplierModal";
import {
  ADD_SUPPLIER,
  DELETE_SUPPLIER,
  EDIT_SUPPLIER,
} from "../graphql/mutations";
import PheonixSnackbar from "../components/PheonixSnackbar";
import PheonixSnackbarProps from "../interfaces/PheonixSnackbarProps";
import { ERROR_MESSAGES } from "../constants";
import SupplierProps from "../interfaces/SupplierProps";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PheonixPagination from "../components/PheonixPagination";
import DeleteModal from "../components/DeleteModal";
import PheonixPaper from "../components/PheonixPaper";
const StyledDiv = styled.div`
  padding: 4px 42px 4px 50px;
  @media (max-width: 707px) {
    padding: 4px 42px 4px 20px;
  }
`;
const SupplierList: React.FC = ({}) => {
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [tableData, setTableData] = useState<SupplierProps[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [email, setemail] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [supplierFormData, setSupplierFormData] = useState({
    "Supplier Name": "",
    "Registration No": "",
    "Phone Number": "",
    Email: "",
    Address: "",
  });
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [iscustomerButtonDisabled, setIscustomerButtonDisabled] =
    useState(true);
  const [filteredSuppliersData, setFilteredSuppliersData] = useState<
    SupplierProps[]
  >([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [supplierIdToDelete, setSupplierIdToDelete] = useState<string>("");
  const [addSupplierMutation] = useMutation(ADD_SUPPLIER);
  const [deleteSupplierMutation] = useMutation(DELETE_SUPPLIER);
  const [editsupplierMutation] = useMutation(EDIT_SUPPLIER);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const limit = rowsPerPage;
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const {
    loading: supplierLoading,
    error: supplierError,
    data: supplierData,
    refetch: supplierRefetch,
  } = useQuery(GET_SUPPLIERS, {
    variables: {
      limit,
      offset: (currentPage - 1) * limit,
    },
    onCompleted: (data) => {
      setTableData(data.getAllSuppliers.suppliers);
      setTableCount(data.getAllSuppliers.totalCount);
      setTotalPages(Math.ceil(data.getAllSuppliers.totalCount / limit));
    },
  });
  const {
    loading: searchLoading,
    error: searchError,
    data: searchData,
    refetch: searchRefetch,
  } = useQuery(SEARCH_SUPPLIERS, {
    variables: {
      searchQuery: searchQuery,
      limit,
      offset: (currentPage - 1) * limit,
    },
    onCompleted: (data) => {
      setFilteredSuppliersData(data.searchSuppliers.suppliers);
      setTableCount(data.searchSuppliers.totalCount);
      setTotalPages(Math.ceil(data.searchSuppliers.totalCount / limit));
    },
  });
  useEffect(() => {
    if (!searchLoading && !searchError && searchData) {
      setFilteredSuppliersData(searchData.searchSuppliers.suppliers);
      setTableCount(searchData.searchSuppliers.totalCount);
      setTotalPages(Math.ceil(searchData.searchSuppliers.totalCount / limit));
    }
  }, [searchData, searchLoading, searchError, searchQuery]);
  useEffect(() => {
    if (!supplierLoading && !supplierError && supplierData) {
      setTableData(supplierData.getAllSuppliers.suppliers);
      setTableCount(supplierData.getAllSuppliers.totalCount);
      setTotalPages(Math.ceil(supplierData.getAllSuppliers.totalCount / limit));
    }
  }, [supplierData, supplierLoading, supplierError]);

  useEffect(() => {
    supplierRefetch();
  }, []);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (page > currentPage) {
      setCurrentPage(currentPage + 1);
    } else if (page < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const updateFormEmptyState = () => {
    const isEmpty = Object.values(supplierFormData).some(
      (value) => value.trim() === ""
    );
    setIsFormEmpty(isEmpty);
  };
  const handleSearch = async (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };
  const handleOpenModal = () => {
    setIsEditModal(false);
    setSupplierFormData({
      "Supplier Name": "",
      "Registration No": "",
      "Phone Number": "",
      Email: "",
      Address: "",
    });
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setSupplierFormData({
      "Supplier Name": "",
      "Registration No": "",
      "Phone Number": "",
      Email: "",
      Address: "",
    });
    setEmailError("");
    setErrorMessage("");
    setRegErrorMessage("");
    setAddressErrorMessage("");
    setNameErrorMessage("");
    setFieldErrors({});
    setIsModalOpen(false);
  };
  const fields = [
    { label: "Supplier Name", type: "text" },
    { label: "Registration No", type: "text" },
    { label: "Phone Number", type: "number" },
    { label: "Email", type: "text" },
    { label: "Address", type: "textarea" },
  ];
  const [editSupplierId, setEditSupplierId] = useState<string>("");
  const handleEditClick = (supplierId: string) => {
    const supplier = tableData.find((supplier) => supplier.id === supplierId);
    if (supplier) {
      setEditSupplierId(supplierId);
      setSupplierFormData({
        "Supplier Name": supplier.supplier_name,
        "Registration No": supplier.registration_no,
        "Phone Number": supplier.phone_no,
        Email: supplier.email,
        Address: supplier.address,
      });
      setIsEditModal(true);
      setIsModalOpen(true);
    }
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [nameerrorMessage, setNameErrorMessage] = useState("");
  const [addresserrorMessage, setAddressErrorMessage] = useState("");
  const [regerrorMessage, setRegErrorMessage] = useState("");
  const [phonnoerrorMessage, setPhonenoErrorMessage] = useState("");
  const handleemailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setemail(e.target.value);
  };
  const handleEmailFocus = () => {
    setErrorMessage("");
  };
  const handleEmailBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
    } else {
      setErrorMessage("");
    }
  };
  function isValidEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const handlePhoneKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!(e.key >= "0" && e.key <= "9") && e.key !== "Backspace") {
      e.preventDefault();
    }
  };
  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setPhoneNo(numericValue);
  };
  const handlePhoneBlur = () => {
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone_no)) {
      setPhonenoErrorMessage(ERROR_MESSAGES.VALID_PHONE);
    } else {
      setPhonenoErrorMessage("");
    }
  };

  function isValidPhoneNumber(phone_no: string) {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone_no);
  }
  const [emailError, setEmailError] = useState("");
  const handleConfirm = async () => {
    let errorMessage: string | null = null;
    switch (true) {
      case !supplierFormData["Supplier Name"]:
        setNameErrorMessage(ERROR_MESSAGES.SUPPLIER_NAME_VALIDATION);
        errorMessage = ERROR_MESSAGES.SUPPLIER_NAME_VALIDATION;
        break;
      case !supplierFormData["Registration No"]:
        setRegErrorMessage(ERROR_MESSAGES.SUPPLIER_REG);
        errorMessage = ERROR_MESSAGES.SUPPLIER_REG;
        break;
      case !isValidPhoneNumber(supplierFormData["Phone Number"]):
        setPhonenoErrorMessage(ERROR_MESSAGES.VALID_PHONE);
        errorMessage = ERROR_MESSAGES.VALID_PHONE;
        break;
      case !isValidEmail(supplierFormData["Email"]):
        setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
        errorMessage = ERROR_MESSAGES.VALID_EMAIL;
        break;
      case !supplierFormData["Address"]:
        setAddressErrorMessage(ERROR_MESSAGES.SUPPLIER_ADDRESS);
        errorMessage = ERROR_MESSAGES.SUPPLIER_ADDRESS;
        break;
      default:
        break;
    }

    if (errorMessage !== null) {
      return;
    }
    try {
      let result;
      if (isEditModal) {
        const updateSupplier = tableData.find(
          (supplier) => supplier.id === editSupplierId
        );
        if (
          updateSupplier &&
          (updateSupplier.supplier_name !== supplierFormData["Supplier Name"] ||
            updateSupplier.phone_no !== supplierFormData["Phone Number"] ||
            updateSupplier.email !== supplierFormData["Email"] ||
            updateSupplier.address !== supplierFormData["Address"] ||
            updateSupplier.registration_no !==
              supplierFormData["Registration No"])
        ) {
          result = await editsupplierMutation({
            variables: {
              id: editSupplierId,
              supplier_name: supplierFormData["Supplier Name"],
              registration_no: supplierFormData["Registration No"],
              phone_no: supplierFormData["Phone Number"],
              email: supplierFormData["Email"],
              address: supplierFormData["Address"],
            },
          });
          if (
            result.data &&
            result.data.editSupplier &&
            result.data.editSupplier.id
          ) {
            const successMessage = result.data.editSupplier.message;

            setSnackbarMessage(successMessage);
            setSnackbarOpen(true);
            handleCloseModal();
            setIsEditModal(false);
            setIsModalOpen(false);
            supplierRefetch();
          } else {
            const message = result.data.editSupplier.message;
            if (message.toLowerCase().includes("email")) {
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                ["Email"]: message,
              }));
            } else if (message.toLowerCase().includes("phone")) {
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                ["Phone Number"]: message,
              }));
            } else if (message.toLowerCase().includes("registeration")) {
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                ["Registration No"]: message,
              }));
            }
          }
        } else {
          setSnackbarMessage(ERROR_MESSAGES.CUSTOMER_NOT_UPDATED);
          setSnackbarOpen(true);
          setIsEditModal(false);
          setIsModalOpen(false);
          handleCloseModal();
        }
      } else {
        result = await addSupplierMutation({
          variables: {
            supplier_name: supplierFormData["Supplier Name"],
            registration_no: supplierFormData["Registration No"],
            phone_no: supplierFormData["Phone Number"],
            email: supplierFormData["Email"],
            address: supplierFormData["Address"],
          },
        });
        if (
          result.data &&
          result.data.createSupplier &&
          result.data.createSupplier.id
        ) {
          const successMessage = result.data.createSupplier.message;
          setSnackbarMessage(successMessage);
          setSnackbarOpen(true);
          handleCloseModal();
          supplierRefetch();
        } else {
          const message = result.data.createSupplier.message;
          if (message.toLowerCase().includes("email")) {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              ["Email"]: message,
            }));
          } else if (message.toLowerCase().includes("phone")) {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              ["Phone Number"]: message,
            }));
          } else if (message.toLowerCase().includes("registeration")) {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              ["Registration No"]: message,
            }));
          }

          setErrorMessage(ERROR_MESSAGES.SERVER_ERR);
        }
      }
    } catch (error: any) {
      if (error.message.toLowerCase().includes("email")) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          ["Email"]: error.message,
        }));
      } else if (error.message.toLowerCase().includes("phone")) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          ["Phone Number"]: error.message,
        }));
      } else if (error.message.toLowerCase().includes("registeration")) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          ["Registration No"]: error.message,
        }));
      }
    }
  };
  const handleDeleteClick = async (supplierId: string) => {
    setSupplierIdToDelete(supplierId);
    setOpenDeleteConfirmation(true);
  };
  const handleDeleteConfirmed = async () => {
    try {
      const result = await deleteSupplierMutation({
        variables: {
          id: supplierIdToDelete,
        },
      });

      supplierRefetch();
      setSnackbarOpen(true);
      setSnackbarMessage(ERROR_MESSAGES.DELETE_SUCCESS_SUPPLIER);
    } catch (error) {
    } finally {
      setOpenDeleteConfirmation(false);
    }
  };
  const columns = [
    { id: "serial_no", label: "S.No" },
    { id: "supplier_code", label: "Supplier Code" },
    { id: "supplier_name", label: "Supplier Name" },
    { id: "registration_no", label: "Registration No" },
    { id: "phone_no", label: "Phone Number" },
    { id: "email", label: "Email" },
    { id: "address", label: "Address" },
    { id: "purchase", label: "No. of Purchase" },
    {
      id: "actions",
      label: "Actions",
      align: "center",
      minWidth: 100,
      render: (row: SupplierProps) => {
        return (
          <div style={{ flexDirection: "row" }}>
            <IconButton
              onClick={() => handleEditClick(row.id)}
              style={{
                color: "#2E7D32",
                border: "none",
                fontSize: "16px",
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteClick(row.id)}
              style={{
                fontSize: "16px",
                color: "#D32F2F",
                border: "none",
                marginLeft: 0,
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ width: "95%" }}>
      <PheonixSnackbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "40px",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        vertical="top"
        horizontal="center"
        contentProps={{ sx: { backgroundColor: "#1A504C" } }}
      />
      <StyledDiv>
        <PheonixPaper
          elevation={1}
          marginLeft={-20}
          marginRight={-30}
          height={100}
          marginTop={35}
          style={{
            border: 0,
            marginBottom: "35px",
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PheonixSearchComponent onChange={handleSearch} />
            <div style={{ marginRight: "25px" }}>
              <PheonixButton
                variant="contained"
                onClick={handleOpenModal}
                style={{
                  color: "white",
                  marginTop: "15px",
                  backgroundColor: "#165D59",
                  fontSize: "16px",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);",
                }}
              >
                {ERROR_MESSAGES.BUTTON_TITLE1}
              </PheonixButton>
            </div>
          </div>
        </PheonixPaper>
        <PheonixTable
          columns={columns}
          data={
            searchQuery && filteredSuppliersData
              ? filteredSuppliersData
              : tableData
          }
          totalPages={tableCount}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          handlePageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledDiv>

      <SupplierModal
        open={isModalOpen}
        close={ERROR_MESSAGES.CLOSE}
        onClose={handleCloseModal}
        title={
          isEditModal
            ? ERROR_MESSAGES.EDIT_SUPPLIER
            : ERROR_MESSAGES.BUTTON_TITLE1
        }
        fields={fields}
        onConfirm={handleConfirm}
        confirmButtonText={isEditModal ? "SAVE" : ERROR_MESSAGES.SAVE}
        formData={supplierFormData}
        setFormData={setSupplierFormData}
        isEditModal={isEditModal}
        errorMessage={errorMessage}
        phonenoErrorMessage={phonnoerrorMessage}
        setErrorMessage={setErrorMessage}
        setPhonenoErrorMessage={setPhonenoErrorMessage}
        nameerrorMessage={nameerrorMessage}
        setNameErrorMessage={setNameErrorMessage}
        addresserrorMessage={addresserrorMessage}
        setAddressErrorMessage={setAddressErrorMessage}
        regerrorMessage={regerrorMessage}
        setRegErrorMessage={setRegErrorMessage}
        onKey={handlePhoneKeyDown}
        onBlur={handleEmailBlur}
        onFocus={handleEmailFocus}
        isFormEmpty={isFormEmpty}
        emailError={emailError}
        fieldErrors1={fieldErrors}
        setFieldErrors1={setFieldErrors}
        updateFormEmptyState={updateFormEmptyState}
        disabled={!iscustomerButtonDisabled}
      />
      <DeleteModal
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onConfirm={handleDeleteConfirmed}
        entity={ERROR_MESSAGES.SUPPLIER_NAME}
      />
    </div>
  );
};
export default SupplierList;
