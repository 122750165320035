export const ERROR_MESSAGES = {
  ENTER_BOTH: "Please enter both email and password",
  VALID_EMAIL: "Enter a valid email",
  VALID_EMAIL1:"Invalid Email Address",
  VALID_PASSWORD: "Please enter a Password",
  EMAILCONFIRM_FAILED: "Failed to confirm email",
  CONFIRMATION_ERROR: "Error during email confirmation",
  EMAILCONFIRM_SUCCESS: "Email confirmed successfully",
  ENTER_ALL: "Please enter all the fields",
  PWD_NOTMATCH: "Passwords do not match",
  PWD_LENGTH: "Passwords should be minimum 8 characters",
  CHECK_MAIL: "Please check your mail for the Confirmation Link",
  ERROR: "ERROR",
  FOOTER_TXT: "Designed & Developed with ❤️ by ",
  PHEONIX_TXT: "Pheonixsolutions",
  VALID_PHONE: "Enter a valid 10 digit phone number",
  VALID_EMAIL_ERROR: "Enter valid mail id",
  BOTH_FIELD: "Enter both Email & Password",
  GENERIC_ERROR: "Login Failed",
  INVALID_PASSWORD: "Invalid password",
  CUSTOMER_NAME_VALIDATION: "Enter Valid Customer Name",
  CUSTOMER_SUCCESS: "Customer added successfully",
  DELETE_SUCCESS: "Customer deleted successfully",
  SUPPLIER_SUCCESS: "Supplier added successfully",
  DELETE_SUCCESS_SUPPLIER: "Supplier deleted successfully",
  CUSTOMER_TITLE: "Customer",
  INVOICE_TITLE: "Invoice",
  SUPPLIER_TITLE: "Supplier",
  BUTTON_TITLE: "Add Customer",
  PRODUCT_TITLE: "Add Product",
  BUTTON_TITLE1: "Add Supplier",
  BUTTON_TITLE_Product: "Add Product",
  PRO_NAME: "Product",
  EDIT_TXT: "Edit",
  DELETE_TXT: "Delete",
  CONFIRM_BUTTONTXT: "ADD CUSTOMER",
  CONFIRM_BUTTONTXT1: "ADD SUPPLIER",
  CONFIRM_BUTTONTXT2: "ADD PRODUCTS",
  CUSTOMER_NAME: "Customer",
  PRODUCT_NAME: "Product",
  SUPPLIER_NAME: "Supplier",
  EDIT_SUPPLIER: "Edit Supplier",
  EMAIL_EXIST: "Email already exists",
  EDIT_CUSTOMER: "Edit Supplier",
  DELETE_CONFIRMATION: "Delete Confirmation",
  DELETE_SUCCESSFULL: "Product deleted successfully",
  DELETE_MSG: "Are you sure you want to delete this ",
  CUSTOMER_NOT_UPDATED:"Fields values are Not Changed",
  DELETE: "Delete",
  SUPPLIER_NAME_VALIDATION: "Enter Supplier Name",
  SUPPLIER_REG: "Enter Registration Number",
  SUPPLIER_ADDRESS: "Enter Address",
  CONFIRM_BUTTONTEXT: "ADD PRODUCTS",
  STOCK_TITLE: "Stock",
  STOCK_BTN: "ADD STOCK",
  STOCK_MDL_TITLE: "Add Stock",
  SAVE: "SAVE",
  CLOSE: "Close",
  STOCK_SUCCESS: "STOCK added successfully",
  DELETE_SUCCESS_STOCK: "STOCK deleted successfully",
  STOCK: "Stock",
  SESSION_ERR_TEXT: "useSession must be used within a SessionProvider",
  CUSTOMER_NAME_REQ: "Enter Valid Customer Name",
  PHONE_NO_VAL: "Phone Number should be 10 digits",
  EMAIL_REQ: "Enter valid Email",
  ADDRESS_REQ: "Enter Address",
  PROD_REQ: "Enter Product name",
  HSN_REQ: "Enter GST",
  GST_VAL:"GST Must contain only Digits",
  BATCH_REQ: "Enter Batch Number",
  MRP_REQ: "Enter MRP",
  MRP_VAL: "MRP must contain only digits",
  PTR_REQ: "Enter Retail Margin",
  PTR_VAL: "Retail Margin must contain only digits",
  DATE_REQ: "Select Expiry Date",
  QUANTITY_REQ: "Enter Quantity",
  QUANTITY_VAL: "Quantity must contain only digits",
  REG_REQ: "Enter Registration No",
  SUPPLIER_REQ: "Enter Valid Supplier Name",
  EMAIL_REG: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PHONE_REG: /^\d{10}$/,
  SERVER_ERR: "Oops! Something went wrong. Please try again later.",
  INVOICE: "CREATE INVOICE",
  EMAL_REQ1: "/S+@S+.S+/",
  CUSTOMERNAME: /^[a-zA-Z\s]+$/,
  INVOICEDATE: "InvoiceDate",
  CUSTOMER_NAME1: "Customer Name",
  PRODUCT_NAME1: "Product Name",
  MORE_QUANTITY: "Unavailable",
  INVOICE_SALES: "Bill To:",
  INVOICE_PURCHASE: "Bill From:",
  INVOICE_SALES_TITLE: "SALES",
  INVOICE_PURCHASE_TITLE: "PURCHASE",
  SUPPLIER_NAME1: "Supplier Name",
  SALES_INVOICE_SUCCESS:
    "Sales Invoice Created Successfully with Invoice Number",
  SALES_DELETE: "Sales Invoice Deleted Successfully",
  SALES_NAME: "Sales Invoice",
  PURCHASE_INVOICE_SUCCESS:
    "Purchase Invoice Created Successfully  with Invoice Number",
  PURCHASE_DELETE: "Purchase Invoice Deleted Successfully",
  PURCHASE_NAME: "Purchase Invoice",
  INVOICE_FAILURE: "Invoice Not Created",
  UPDATE_INVOICE: "UPDATE",
  CHECKBOX_ERROR: "*Please select atleast one option",
  TAX_TEXT: "Tax Applicable",
  ITEM_TEXT: "Total Items",
  AMOUNT_TEXT: "Total Amount",
  TAX: "Tax",
  QUANTITY_TEXT: "Total Quantity",
  CGST: "CGST",
  SGST: "SGST",
  IGST: "IGST",
  NET_AMOUNT: "Net Amount",
  DISCOUNT: "Discount",
  CANCEL: "Cancel",
  NET_PAID: "Net Amount Paid",
  ADD_ITEM: "Add Item",
  ADD_NEW_PRODUCT: "Add New Product",
};
export const PHARMA_TEXT = "PHARMA";
export const Sales_TEXT = "Sales";
export const Purchase_TEXT = "Purchase";
export const Login_TEXT = "Login";
export const Product_EDIT = "Edit";
export const Product_Delete = "Delete";
export const Product_List = "PRODUCT LIST";
export const Add_Product = "ADD PRODUCTS";
export const Product_Stack = "Product stock";
export const Top_Selling = "Top Selling";
export const Sales = "Sales";
export const Purchase = "Purchase";
export const Tables = "No data available";
export const INVOICE_NUMBER: string = "InvoiceNumber";
export const pdfdata = {
  PHARMA_ADDRESS: `
  1234 Elm Street,
  Springfield, Anytown 54321,
  United States`,
  BANK_DETAILS: `
  Bank Details:
  Kotak Mahindra Bank
  Kanjikovil Branch,
  A/C No: 415044038837
  IFS Code: KKBK0008774`,
};
